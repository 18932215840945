<template>
  <div v-if="mainConfig.showPrevNextList"
       class="ta-lot-next-previous d-flex justify-content-between align-items-center text-sm">
    <a href="#" :class="{
        'd-flex align-items-center': true,
        'disabled-anchor': !lotObject || !lotObject.data || !lotObject.data.previous,
        'text-muted': !lotObject || !lotObject.data || !lotObject.data.previous,
        }"
       @click.prevent="previousLot()">
      <svg xmlns="http://www.w3.org/2000/svg"
           class="w-4 mr-2"
           fill="none" viewBox="0 0 24 24"
           stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"/>
      </svg>
      {{ $t('Previous') }}
    </a>

    <a href="#" @click.prevent="backToList()">
      {{ $t('Back to List') }}
    </a>

    <a href="#" :class="{
        'd-flex align-items-center': true,
        'disabled-anchor': !lotObject || !lotObject.data || !lotObject.data.next,
        'text-muted': !lotObject || !lotObject.data || !lotObject.data.next,
        }"
       @click.prevent="nextLot()">
      {{ $t('Next') }}
      <svg xmlns="http://www.w3.org/2000/svg"
           class="w-4 ml-2"
           fill="none" viewBox="0 0 24 24"
           stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"/>
      </svg>
    </a>
  </div>
</template>

<script>
import {mapState, mapMutations} from "vuex";
import {getLotsQuery, goBackToList} from "@/helpers";
import {lotBackToListClick, lotNextClick, lotPrevClick} from "@/triggers";
import lotHelperMixin from "@/mixins/lotHelperMixin";

export default {
  name: "LotViewPrevNextButtons",
  mixins: [lotHelperMixin],
  computed: {
    ...mapState(['mainConfig', 'lotObject', 'lotQueryParams']),
    ...mapState({
      lot: state => state.lotObject.data
    }),
  },
  methods: {
    ...mapMutations(['setLotFilters', 'emptyLotFilters']),
    previousLot() {
      lotPrevClick(this.title)
      if (!this.lotObject || !this.lotObject.data || !this.lotObject.data.previous) {
        return;
      }
      this.navigateToLot(this.lotObject.data.previous);
    },
    nextLot() {
      lotNextClick(this.title)
      if (!this.lotObject || !this.lotObject.data || !this.lotObject.data.next) {
        return;
      }
      this.navigateToLot(this.lotObject.data.next);
    },
    navigateToLot(lotno) {
      const query = this.$route.query

      this.$router.push({name: 'LotView', params: {lotno}, query});
    },
    backToList() {
      lotBackToListClick(this.title)
      return goBackToList(this.$route.query)
    }
  },
  mounted() {
    const query = this.$route.query;

    if (query.i_made_bid) {
      this.setLotFilters({
        i_made_bid: 1
      });
    }
  },
  destroyed() {
    const query = {...this.$route.query};

    // Delete this flag and update in vuex to avoid incorrect data fetch on other pages.
    delete query.i_made_bid

    if (this.lotQueryParams.auction_uuid) {
      query.auction_uuid = this.lotQueryParams.auction_uuid;
    }
    this.emptyLotFilters();
    this.setLotFilters({
      ...query,
    });
  }
}
</script>

<style lang="scss" scoped>

#artisioTimedAuctions {
  .disabled-anchor {
    pointer-events: none;
  }
}
</style>
