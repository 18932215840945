import axios from "@/services/axios";

const Stripe = {
  _ajaxUrl: '/website/auth/payments/ajax', // See createTransaction() and getTransaction() to change how fields are posted
  _pollingInterval: null,
  // ajax e.g. post('your/url', 'transactionId=123&userid=123', function (err, data) { /*check for error and handle data*/ });
  post: function (url, postData, callback) {
    axios.post(url, postData)
      .then(response => {
        callback(null, response.data)
      })
      .catch(err => {
        callback(new Error("Request failed. " + err.response.status));
      })
  },

  createTransaction: function (amount, callback) {
    this.post(
      this._ajaxUrl,
      {
        'method': 'make_transaction',
        'amount': amount
      },
      function (err, txn) {
        if (err || txn.hasError || txn.errorCode > 0 || !txn.client_secret) {
          return callback(new Error("Error occurred while making transaction: " + (err || txn.errorMessage)));
        }

        callback(null, txn);
      }
    )
  },
  getTransaction: function (transactionId, callback) {
    this.post(
      this._ajaxUrl,
      {
        'method': 'get_transaction',
        'transaction_id': transactionId
      },
      function (err, transaction) {
        if (err) {
          return callback(err);
        }

        callback(null, transaction);
      }
    );
  },
};

export default Stripe
