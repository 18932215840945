import Vue from 'vue'
import VueRouter from 'vue-router'
import AuctionListPage from "../views/AuctionListPage/AuctionListPage";
import Invoices from "../views/Invoices";
import Settlements from "../views/Settlements";
import Items from "../views/Items";
import TemporaryItems from "../views/TemporaryItems";
import AuctionView from "../views/AuctionViewPage/AuctionViewPage";
import Lots from "../views/Lots";
import LotView from "../views/LotView";
import Login from "../views/auth/Login";
import Signup from "../views/auth/Signup";
import ForgotPassword from "../views/auth/ForgotPassword";
import ResetPassword from "../views/auth/ResetPassword";
import AuthLayout from "../components/layouts/AuthLayout";
import DefaultLayout from "../components/layouts/DefaultLayout";
import NotFound from "../components/NotFound";
import CONFIG from "../user-config";
import store from '../store';
import VerifyEmail from "../views/auth/VerifyEmail";
import Home from "../views/Home";
import Profile from "../views/Profile";
import Signout from "../views/auth/Signout";
import FavoriteAuctions from "../views/FavoriteAuctions";
import FavoriteLots from "../views/FavoriteLots";
import OptOut from "@/views/OptOut";
import LotsIMadeBid from "@/views/LotsIMadeBid.vue";
import {REDIRECT_AFTER_LOGIN_SESSION_STORAGE_KEY} from "@/constants";
import MyTelephoneBids from "@/views/MyTelephoneBids.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: CONFIG.defaultPage
  },
  {
    path: '/app',
    name: 'App',
    redirect: CONFIG.defaultPage,
    component: DefaultLayout,
    children: [
      {
        path: '/home',
        name: 'Home',
        component: Home
      },
      // Auctions
      // ----------------------------
      {
        path: '/auctions',
        name: 'AuctionsTimed',
        component: AuctionListPage
      },
      {
        path: '/auctions/:uuid',
        name: 'AuctionViewTimed',
        component: AuctionView
      },
      {
        path: '/private-treaty/auctions',
        name: 'PrivateTreatyAuctions',
        component: AuctionListPage
      },
      {
        path: '/private-treaty/auctions/:uuid',
        name: 'PrivateTreatyAuctionView',
        component: AuctionView
      },
      {
        path: '/auctions/catalog',
        name: 'AuctionsCatalog',
        component: AuctionListPage
      },
      {
        path: '/auctions/catalog/:uuid',
        name: 'AuctionViewCatalog',
        component: AuctionView
      },
      {
        path: '/favorite-auctions/',
        name: 'FavoriteAuctions',
        meta: {requiresAuth: true},
        component: FavoriteAuctions
      },
      // Lots
      // -------------------
      {
        path: '/lots/',
        name: 'Lots',
        component: Lots
      },
      {
        path: '/lots/:lotno',
        name: 'LotView',
        component: LotView
      },
      {
        path: '/private-treaty/lots',
        name: 'PrivateTreatyLots',
        component: Lots
      },
      {
        path: '/private-treaty/lots/:lotno',
        name: 'PrivateTreatyLotView',
        component: LotView
      },
      {
        path: '/favorite-lots/',
        name: 'FavoriteLots',
        meta: {requiresAuth: true},
        component: FavoriteLots
      },
      {
        path: '/lots-i-made-a-bid',
        redirect: '/my-bids',
      },
      {
        path: '/my-bids/',
        name: 'IMadeBidLots',
        meta: {requiresAuth: true},
        component: LotsIMadeBid
      },
      {
        path: '/my-telephone-bids',
        name: 'TelephoneBids',
        meta: {requiresAuth: true},
        component: MyTelephoneBids
      },
      {
        path: '/invoices',
        name: 'invoices',
        meta: {requiresAuth: true},
        component: Invoices
      },
      {
        path: '/settlements',
        name: 'settlements',
        meta: {requiresAuth: true},
        component: Settlements
      },
      {
        path: '/items',
        name: 'items',
        meta: {requiresAuth: true},
        component: Items
      },
      {
        path: '/purchased-items',
        name: 'purchased-items',
        meta: {requiresAuth: true},
        component: Items,
        props: {
          purchasedItemsMode: true
        }
      },
      {
        path: '/temporary-items',
        redirect: '/sell-an-item-at-auction',
      },
      {
        path: '/sell-an-item-at-auction',
        name: 'temporary-items',
        meta: {requiresAuth: true},
        component: TemporaryItems
      },
      {
        path: '/profile',
        name: 'profile',
        meta: {requiresAuth: true},
        component: Profile
      },
      {
        path: '/sign-out',
        name: 'SignOut',
        meta: {requiresAuth: true},
        component: Signout
      },
    ]
  },
  {
    path: '/auth',
    name: 'Auth',
    redirect: '/login',
    component: AuthLayout,
    meta: {forGuestOnly: true},
    children: [
      {
        path: '/login',
        name: 'Login',
        meta: {forGuestOnly: true},
        component: Login
      },
      {
        path: '/forgot-password',
        name: 'ForgotPassword',
        meta: {forGuestOnly: true},
        component: ForgotPassword
      },
      {
        path: '/reset-password/:token',
        name: 'ResetPassword',
        meta: {forGuestOnly: true},
        component: ResetPassword
      },
      {
        path: '/verify-email/:token',
        name: 'VerifyEmail',
        meta: {forGuestOnly: true},
        component: VerifyEmail
      },
    ]
  },
  {
    path: '/signup',
    name: 'Signup',
    meta: {forGuestOnly: true},
    component: Signup
  },
  {
    path: '/opt-out/:token',
    name: 'OptOut',
    component: OptOut
  },
  {
    path: '/404',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/:catchAll(.*)',
    redirect: '404'
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const cookie = Vue.prototype.$cookie.get(CONFIG.artisioCookieName)
  const redirectUrl = localStorage.getItem(REDIRECT_AFTER_LOGIN_SESSION_STORAGE_KEY)
  if (redirectUrl && cookie) {
    localStorage.removeItem(REDIRECT_AFTER_LOGIN_SESSION_STORAGE_KEY)
    // next(redirectUrl)
    window.location.href = redirectUrl;
  } else {
    if (to.meta.requiresAuth && !cookie) {
      next({name: 'Login'})
    } else {
      next()
    }
  }
})

router.afterEach((to, from) => {
  const body = document.body;
  if (from.name) {
    let fromPage = from.name
      .replace(/[A-Z]/g, m => "-" + m.toLowerCase())
      .replace(/^-/, '');
    body.classList.remove(`artisio-webapp-page-${fromPage}`)
  }

  if (to.name) {
    let toPage = to.name
      .replace(/[A-Z]/g, m => "-" + m.toLowerCase())
      .replace(/^-/, '');
    body.classList.add(`artisio-webapp-page-${toPage}`)
  }
})

export default router
