import moment from "moment/moment";
import {getCurrentServerTime} from "@/helpers";

export default {
  computed: {
    auctionCoverImg() {
      if (!this.auction.images || !this.auction.images.length) {
        return null;
      }
      else if (this.auction.images.length >= 2) {
        return this.auction.images[1].lg.url
      }
      else {
        return this.auction.images[0].lg.url
      }
    },
    viewingDates() {
      const startDate = this.$options.filters.date(this.auction.viewing_start_date)
      const endDate = this.$options.filters.date(this.auction.viewing_end_date)

      if (startDate !== endDate) {
        return `${this.$options.filters.datetime(this.auction.viewing_start_date)} - ${this.$options.filters.datetime(this.auction.viewing_end_date)}`
      }

      const startTime = this.$options.filters.time(this.auction.viewing_start_date)
      const endTime = this.$options.filters.time(this.auction.viewing_end_date)
      return `${startDate} | ${startTime} - ${endTime}`
    },
    privateViewingDates() {
      const startDate = this.$options.filters.date(this.auction.private_viewing_start_date)
      const endDate = this.$options.filters.date(this.auction.private_viewing_end_date)

      if (startDate !== endDate) {
        return `${this.$options.filters.datetime(this.auction.private_viewing_start_date)} - ${this.$options.filters.datetime(this.auction.private_viewing_end_date)}`
      }

      const startTime = this.$options.filters.time(this.auction.private_viewing_start_date)
      const endTime = this.$options.filters.time(this.auction.private_viewing_end_date)
      return `${startDate} | ${startTime} - ${endTime}`
    },
    locationInfo() {
      return this.$options.filters.currentLocale(this.auction.auction_location)
    },
    contactInfo() {
      return this.$options.filters.currentLocale(this.auction.contact_information)
    },
    viewingInfo() {
      return this.$options.filters.currentLocale(this.auction.viewing_information)
    },
    importantInfo() {
      return this.$options.filters.currentLocale(this.auction.important_information)
    },
    collectionInfo() {
      return this.$options.filters.currentLocale(this.auction.collection_information)
    },
    paymentInfo() {
      return this.$options.filters.currentLocale(this.auction.payment_information)
    },
    termsAndConditions() {
      return this.$options.filters.currentLocale(this.auction.terms_and_conditions) ?? this.mainConfig.termsAndConditionsHtml;
    }
  },
  methods: {
    shouldShowRegisterAsBidderButton(auction) {
      let eventTime =
        auction.after_sale_end_date
          ? moment(auction.after_sale_end_date).toDate().getTime()
          : 0;
      return (!this.mainConfig.allowBidderAutoRegistration || auction.manual_bidder_approval || auction.initial_deposit_amount || auction.require_credit_card_verification || auction.type === 'live')
        && !auction.is_bidder && (auction.status === 'published' || (auction.status === 'completed' && eventTime > getCurrentServerTime()))
    }
  }
}
