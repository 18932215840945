<template>
  <a href="javascript:void(0)" @click="onLogin($event)" class="ta-link-to-login">
    <slot />
  </a>
</template>

<script>
import {openLogin} from "@/helpers";
export default {
  name: "LinkToLogin",
  methods: {
    onLogin(ev) {
      openLogin(ev)
    }
  }
}
</script>

<style scoped>

</style>
