<template>
  <div v-if="lot.currency && lot.pre_bid" class="ta-lot-max-bid-price">
    <label class="ta-lot-max-bid-label">
      <template v-if="lot.pre_bid.type === 'max_bid'">{{ myMaxBidLabel }}</template>
      <template v-else-if="lot.pre_bid.type === 'commission'">{{ myCommissionBidLabel }}</template>
      <template v-else-if="lot.pre_bid.type === 'telephone'">
        {{ telephoneBidLabel }}
        <span :id="`lot-telephone-bid-${lot.uuid}`" v-b-tooltip.hover>
            <b-icon icon="telephone-fill" aria-hidden="true" class="text-sm ml-2 text-success"
                    :class="{
                      'text-success': lot.pre_bid.status === 'confirmed',
                      'text-warning': lot.pre_bid.status === 'pending',
                      'text-danger': lot.pre_bid.status === 'rejected'
                    }"/>
          </span>
      </template>
      <template v-else>{{ label }}</template>
    </label>
    <span class="ta-lot-max-bid-amount">
        {{ (lot.pre_bid.amount) | asCurrency(lot.currency.code) }}
      </span>
    <b-tooltip container="artisioTimedAuctions" :target="`lot-telephone-bid-${lot.uuid}`">
      <template v-if="lot.pre_bid.status === 'confirmed'">
        {{ $t('Your Status is Approved') }}
      </template>
      <template v-else-if="lot.pre_bid.status === 'pending'">
        {{ $t('Your Status is Pending') }}
      </template>
      <template v-else-if="lot.pre_bid.status === 'rejected'">
        {{ $t('Your Status is Rejected') }}
      </template>
    </b-tooltip>
  </div>
</template>

<script>
export default {
  name: "LotMyMaxBid",
  props: {
    lot: {
      required: true,
      type: Object
    },
    label: {
      required: false,
      type: [String, Boolean],
      default() {
        return this.$t('My Max/Commission Bid')
      }
    },
    myMaxBidLabel: {
      required: false,
      type: [String, Boolean],
      default() {
        return this.$t('My Max Bid')
      }
    },
    myCommissionBidLabel: {
      required: false,
      type: [String, Boolean],
      default() {
        return this.$t('My Commission Bid')
      }
    },
    telephoneBidLabel: {
      required: false,
      type: [String, Boolean],
      default() {
        return this.$t('My Telephone Bid')
      }
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../../bootstrap/import";

#artisioTimedAuctions {
  .ta-lot-max-bid-price {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ta-lot-max-bid-label {
      margin-bottom: 0;
    }

    .ta-lot-max-bid-amount {
      color: $primary;
      font-weight: bold;
      font-size: $font-size-base;
    }
  }
}
</style>
