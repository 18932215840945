<template>
  <span v-if="end_date && finished" class="ta-countdown ta-countdown-ended">
    {{ $t("Ended") }}
  </span>
  <div v-else-if="!end_date && started" class="ta-countdown">
    {{ $t('Auction has Started') }}
  </div>
  <div v-else class="ta-countdown" :class="badgeClass">
    <small v-if="!started && label" class="ta-countdown-label">{{ $t("Opens in") }}</small>
    <small v-if="started && !finished && label" class="ta-countdown-label">{{ $t("Ends in") }}</small>
    <div class="ta-countdown-value">
      <svg
        fill="currentColor"
        class="mr-2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width="16"
        height="16"
      >
        <path
          fill-rule="evenodd"
          d="M1.5 8a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zM8 0a8 8 0 100 16A8 8 0 008 0zm.5 4.75a.75.75 0 00-1.5 0v3.5a.75.75 0 00.471.696l2.5 1a.75.75 0 00.557-1.392L8.5 7.742V4.75z"
        ></path>
      </svg>
      {{formattedTime}}
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {mapState, mapGetters} from "vuex";
import {getCurrentServerTime} from "@/helpers";

export default {
  name: "CountDownTimer",
  props: {
    start_date: {
      type: [String, Date],
      required: true,
    },
    end_date: {
      type: [String, Date],
      required: false,
    },
    label: {
      type: [Boolean],
      default: true
    },
    paused: {
      type: [Boolean],
      default: false
    }
  },
  data() {
    return {
      myDatetime: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      interval: null,
      finished: false,
      started: false,

      badgeClass: null,
    };
  },
  computed: {
    ...mapState(["clientTimeWhenServerResponded", "serverTime", 'mainConfig']),
    ...mapGetters(['currentLocale']),
    startOfEventTime() {
      let startEventTime =
        typeof this.start_date === "string"
          ? moment(this.start_date).toDate().getTime()
          : this.start_date.getTime();

      return moment(startEventTime);
    },

    endOfEventTime() {
      if (!this.end_date) return null;

      // console.log(this.end_date)
      let endEventTime =
        typeof this.end_date === "string"
          ? moment(this.end_date).toDate().getTime()
          : this.end_date.getTime();
      return moment(endEventTime);
    },
    formattedTime() {
      let result = [];
      if (this.paused) {
        result = ['--', '--']
      } else {
        if (this.myDatetime.days) {
          result.push(`${this.myDatetime.days}${this.getTranslatableDuration('d')}`)
        }
        if (this.myDatetime.days || this.myDatetime.hours) {
          result.push(`${this.myDatetime.hours}${this.getTranslatableDuration('h')}`)
        }
        if (!this.myDatetime.days) {
          result.push(`${this.myDatetime.minutes}${this.getTranslatableDuration('m')}`)
        }
        if (!this.myDatetime.days && !this.myDatetime.hours) {
          result.push(`${this.myDatetime.seconds}${this.getTranslatableDuration('s')}`)
        }
      }
      return result.join(' ')
    }
  },
  methods: {
    updateUI() {

      // const timeDiff = moment.duration(moment(new Date()).diff(this.serverTime));
      const currentTime = getCurrentServerTime()
      // console.log((new Date(this.serverTime)).getTime())
      // console.log(currentTime)
      // console.log("==========================")

      this.started = this.startOfEventTime <= currentTime;
      this.finished = false;

      const eventTime = !this.started
        ? this.startOfEventTime
        : this.endOfEventTime;


      this.badgeClass = !this.started
        ? "ta-countdown-not-started"
        : "ta-countdown-started";

      if (this.endOfEventTime && currentTime > this.endOfEventTime && !this.paused) {
        // clearInterval(this.interval);
        this.finished = true;
        return;
      }
      const diffTime = eventTime - currentTime;
      const duration = moment.duration(diffTime, "milliseconds");
      this.myDatetime = {
        days: Math.floor(duration.asDays()),
        hours: duration.hours(),
        minutes: duration.minutes(),
        seconds: duration.seconds(),
      };
    },
    getTranslatableDuration(a) {
      const tmp = this.mainConfig.countdownDurationTranslations[this.currentLocale]
      return (tmp && tmp[a]) || a;
    }
  },
  mounted() {
    if (!this.start_date && !this.end_date) {
      return;
    }
    this.updateUI();
    const interval = 1000;

    this.interval = setInterval(() => {
      this.updateUI();
    }, interval);
  },

  destroyed() {
    clearInterval(this.interval);
  },
};
</script>

<style scoped lang="scss">
@import "../bootstrap/import.scss";

#artisioTimedAuctions {
  .ta-countdown {
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    font-size: 90%;
    white-space: nowrap;
    //background: fade-out($dark, 0.2);
    //color: $white;
  }

  .ta-countdown-label {
    margin-right: 0.5rem;
  }

  .ta-countdown-value {
    display: flex;
    align-items: center;
  }
}
</style>
