import uuid from 'uuid';
import Vue from "vue";
import moment from "moment";
import i18n from "../i18n";
import {asCurrency} from "../filters/currency";
import store from "./index";
import router from "../router/index";
import CONFIG from "../user-config";
import {
  AUCTION_DEFAULT_FILTERS,
  LOT_DEFAULT_FILTERS,
  PROFILE_DEFAULT_ERRORS,
  PROFILE_DEFAULT_VALUE,
  SELL_AN_ITEM_INITIAL_MODEL
} from "./state";
import {afterLogout, openLogin} from "@/helpers";
import logger from "@/logger";

export const setAuctionsLoading = (state, loading) => {
  state.auctions.loading = loading
  if (loading) {
    state.auctions.loaded = false
  }
};

export const setAuctionsLoaded = (state) => {
  state.auctions.loaded = true
};

export const setAuctions = (state, auctions) => {
  state.auctions.results = auctions.result.map((l) => {
    return setLotActualDates(l);
  });
  state.auctions.totalRows = auctions.count;
};
export const setAuctionObjectLoading = (state, loading) =>
  (state.auctionObject.loading = loading);

export const setAuctionObjectData = (state, data) => {
  // data.auction_location_info= {'en': 'Lorem ipsum'}
  // data.contact_info = {'en': 'Zura Sekhniashvili'}
  // data.viewing_info = {'en': 'Viewing is only allowed...'}
  // data.viewing_start_date = '2022-04-09 10:00'
  // data.viewing_end_date = '2022-04-10 10:00'
  state.auctionObject.data = setLotActualDates(data);

  state.lots = {
    ...state.lots,
    results: state.lots.results.map((l) => {
      l.auction = {
        ...(l.auction || {}),
        ...(state.auctionObject.data || {}),
      };
      return setLotActualDates(l, state.timeDiff);
    })
  }
};

export const mutateAuctionPagination = (state, pagination) => {
  state.auctions.pagination = pagination;
};
export const mutateAuctionFilters = (state, filters) => {
  state.auctions.filters = filters;
};
export const mutateTimeDiff = (state, server_time) => {
  state.timeDiff = moment.duration(moment(new Date()).diff(server_time));
};

export const showLotInquiryModal = (state, payload) => {
  state.lots.lotInquiryModal.show = true;
  state.lots.lotInquiryModal.lot = payload.lot;
}

export const hideLotInquiryModal = (state) => {
  state.lots.lotInquiryModal.show = false;
  state.lots.lotInquiryModal.lot = null;
}

export const setLotsLoading = (state, loading) => {
  state.lots.loading = loading
  if (loading) {
    state.lots.loaded = false
  }
};

export const setLotsLoaded = (state) => {
  state.lots.loaded = true
};

export const emptyAuctions = (state) => {
  state.auctions.results = [];
  state.auctions.totalRows = 0;
};
export const emptyLots = (state) => {
  state.lots.results = [];
  state.lots.totalRows = 0;
};
export const setLots = (state, lots) => {
  state.lots.results = [...state.lots.results, ...lots.results.map((l) => {
    l.auction = {
      ...(state.auctionObject.data || {}),
      ...(l.auction || {})
    };
    return setLotActualDates(l);
  })];
  state.lots.totalRows = lots.count;
};
export const setLotResults = (state, lotResults) => {
  state.lots = {
    ...state.lots,
    results: lotResults,
  }
};
// export const setFavoriteLots = (state, lots) => {
//   state.lots.results = lots.results.map((l) => {
//     return setLotActualDates(l, state.timeDiff);
//   });
//   state.lots.totalRows = lots.count;
// };
export const setLotObjectLoading = (state, loading) =>
  (state.lotObject.loading = loading);


export const setLotObjectData = (state, l) => {
  if (l.reserve) {
    l.reserve = parseFloat(l.reserve)
  }
  if (l.start_price) {
    l.start_price = parseFloat(l.start_price)
  }
  if (l.winning_bid && l.winning_bid.amount) {
    l.winning_bid.amount = parseFloat(l.winning_bid.amount)
  }

  if (l.last_bid_amount) {
    l.last_bid_amount = parseFloat(l.last_bid_amount)
  }

  state.lotObject.data = setLotActualDates(l);
};

export const expandLotLastBids = (state, data) => {
  state.lotObject.data.last_bids = [...state.lotObject.data.last_bids, ...data];
};

export const mutateLotObject = (state, {data, fetch}) => {
  // Get lot from lot list
  let lotListItem = state.lots.results.find((lot) => lot.uuid === data.uuid);

  // If the updated lot is the lot which is opened at the moment we must updated opened lot details
  let lot = state.lotObject.data;
  if (!lot.uuid) {
    lot = lotListItem
  }
  if (lot && lot.uuid === data.uuid) {
    data = {
      ...setLotActualDates(data),
      hasBids: !!data.winning_bid,
    }
    if (typeof data.has_my_bid === 'undefined' && data.last_bids && !data.has_my_bid) {
      data.has_my_bid = data.last_bids.some(b => b.paddle_no === state.customer.customer_no)
    }

    // Get the winning bid information
    const last_activity_date = lot.last_activity_date || 1;


    if (data.last_activity_date < last_activity_date) {
      data.winning_bid = lot.winning_bid;
      data.num_of_bids = lot.num_of_bids;
    }

    // If this is not initial fetch request and winning bid customer is currently authorized customer
    const selfBid = !fetch && data.winning_bid && data.winning_bid?.customer_uuid === state.customer.uuid;

    // If I made the bid
    if (selfBid && data.status !== 'unsold') {

      // If I have not made bid before
      if (!lot.has_my_bid) {
        lot.has_my_bid = true;
      }
      if (!data.winning_bid.buy_now) {
        // show notification
        store.commit("showNotification", {
          title: i18n.t("New Bid!"),
          text: i18n.t("You have created a new bid with amount of {amount}", {
            amount: '<strong>' + asCurrency(data.winning_bid.amount, lot.currency.code) + '</strong>',
          }),
          variant: "success",
        });
      }
    }

    if (state.lotObject.data.uuid) {
      state.lotObject.data = {
        ...lot,
        ...data
      }
    } else if (lotListItem) {
      for (const key in data) {
        Vue.set(lotListItem, key, data[key]);
      }
      Vue.set(lotListItem, 'hash', uuid.v4());
      // state.lots.results = [...state.lots.results]
    }
  }

};

export const mutateBidderStatus = (state, payload) => {
  let auction = state.auctions.results.filter(
    (auction) => auction.uuid === payload.auction_uuid
  )[0];
  let auctionObject =
    state.auctionObject.data.uuid === payload.auction_uuid
      ? state.auctionObject.data
      : null;

  if (auction) auction.is_bidder = payload.bid_allowed;

  if (auctionObject) {
    auctionObject.is_bidder = payload.bid_allowed;

    if (state.lotObject.data.auction_uuid === auctionObject.uuid) {
      // state.lotObject.data.max_bid = null;
      state.lotObject.data.auction.is_bidder = payload.bid_allowed;
    }
  }
};

export const mutateLotMaxBid = (state, response) => {
  if (state.lotObject.data.uuid) {
    if (response.type !== 'telephone') {
      state.lotObject.data.max_bid = response.amount;
    }
    state.lotObject.data.pre_bid = response;
  } else {
    state.lots.results = state.lots.results.map(lot => {
      if (lot.uuid === response.lot_uuid) {
        if (response.type !== 'telephone') {
          lot.max_bid = response.amount
        }
        lot.pre_bid = response;
      }
      return lot
    })
  }
};
export const deleteLotMaxBid = (state) => {
  state.lotObject.data.max_bid = null;
  state.lotObject.data.max_bid_status = null;
  state.lotObject.data.pre_bid = null;
};
export const mutateLotFilters = (state, filters) => {
  state.lots.filters = filters;
};
export const mutateCustomerMeta = (state, data) => {
  state.customer = data;
};
export const showNotification = (state, data) => {
  data["visible"] = true;
  data["autoHideDelay"] = data["duration"] ?? 3000;
  state.toast = data;
};
export const showModalMsgBox = (state, data) => {
  data["visible"] = true;
  data["headerBgVariant"] = data["headerBgVariant"] ?? "danger";
  data["headerTextVariant"] = data["headerTextVariant"] ?? "light";
  state.modalMsgBox = data;
};
export const mutateIsAuthorized = (state, is_authorized) => {
  state.is_authorized = is_authorized;
};
export const mainConfig = (state, config) => {
  state.mainConfig = {
    ...state.mainConfig,
    ...config,
  };
};
export const fireSignal = (state) => {
  state.signal = Number(!state.signal);
};
export const changeLotView = (state, viewType) => {
  state.lotListViewType = viewType;
  localStorage.setItem("ARTISIO_LOT_LIST_VIEW_TYPE", viewType);
};
export const changeAuctionView = (state, viewType) => {
  state.auctionListViewType = viewType;
  localStorage.setItem("ARTISIO_AUCTION_LIST_VIEW_TYPE", viewType);
};
export const markDoNotShowConfirmOnBid = (state, lot) => {
  if (!state.doNotShowConfirmOnBid) {
    state.doNotShowConfirmOnBid = {};
  }
  state.doNotShowConfirmOnBid[lot] = true;
  localStorage.setItem(
    "ARTISIO_DO_NOT_SHOW_CONFIRM_ON_BID",
    JSON.stringify(state.doNotShowConfirmOnBid)
  );
};
export const setHasMyBid = (state, lot_uuid) => {
  if (state.lotObject.data.uuid) {
    state.lotObject.data.has_my_bid = true;
  } else {
    state.lots.results = state.lots.results.map(lot => {
      if (lot.uuid === lot_uuid) {
        state.lotObject.data.has_my_bid = true;
      }
      return lot
    })
  }
};
export const SOCKET_ONOPEN = (state, event) => {
  Vue.prototype.$socket = event.currentTarget
  state.socket.isOpened = true;
  state.socket.disconnectTime = null;
  store.dispatch('sendSocketLogin');
  if (state.lotObject && state.lotObject.data && state.lotObject.data.uuid) {
    store.dispatch('getLotData', {uuid: state.lotObject.data.uuid, stopLoading: true});
  } else if (state.lots && state.lots.results && state.lots.results.length) {
    store.dispatch('getLots', {stopLoading: true});
  }
  console.log("SOCKET_ONOPEN ");
};
export const SOCKET_ONERROR = (state, arg) => {
  console.log("SOCKET_ONERROR ", arg);
};
export const SOCKET_ONCLOSE = (state, arg) => {
  state.socket.isOpened = false;
  state.socket.loginFinished = false;
  if (state.socket.disconnectTime === null) {
    state.socket.disconnectTime = Date.now();
  }
  // state.socketSubscribedLots = {};
  console.log("SOCKET_ONCLOSE ", arg);
};
export const SOCKET_RECONNECT = (state, arg) => {
  console.log("SOCKET_RECONNECT ", arg);
};
export const SOCKET_ONMESSAGE = (state, arg) => {
  console.log("SOCKET_ONMESSAGE ", arg);
};

export const setCurrentUser = (state, data) => {
  const expires = {expires: data.remember ? data.expires_at : "session"}
  Vue.cookie.set(CONFIG.artisioCookieName, data.token, expires);
};

export const setCurrentCustomer = (state, customer) => {
  state.is_authorized = true;
  state.customer = customer;
};

export const removeCurrentUser = () => {
  Vue.cookie.delete(CONFIG.artisioCookieName);
};

export const setLotFilters = (state, filters) => {
  if (filters.department && typeof filters.department === 'string') {
    filters.department = [filters.department]
  }
  if (filters.category && typeof filters.category === 'string') {
    filters.category = [filters.category]
  }

  let dynamicFields = {};

  if (filters.dynamicFields) {
    dynamicFields = Object.fromEntries(
      Object.entries(filters.dynamicFields).map(([key, value]) => [`dynamic_fields-${key}`, value])
    );
    delete filters.dynamicFields;
  }

  state.lotQueryParams = {
    ...state.lotQueryParams,
    ...filters,
    ...dynamicFields
  };
};

export const setAuctionFilters = (state, filters) => {
  if (filters.department && typeof filters.department === 'string') {
    filters.department = [filters.department]
  }
  if (filters.category && typeof filters.category === 'string') {
    filters.category = [filters.category]
  }

  state.auctionQueryParams = {
    ...state.auctionQueryParams,
    ...filters,
  };
};

export const setFavoriteAuctionFilters = (state, filters) => {
  state.favoriteAuctionQueryParams = {
    ...state.favoriteAuctionQueryParams,
    ...filters,
  };
};

export const emptyLotFilters = (state) => {
  state.lotQueryParams = JSON.parse(JSON.stringify(LOT_DEFAULT_FILTERS));
};

export const emptyAuctionFilters = (state) => {
  state.auctionQueryParams = JSON.parse(JSON.stringify(AUCTION_DEFAULT_FILTERS));
};

export const setDepartmentsLoading = (state, loading) => (state.departments.loading = loading);
export const setDepartments = (state, data) => {
  state.departments.data = data;
};
export const setLocationStatesLoading = (state, loading) => (state.locationStates.loading = loading);
export const setLocationStates = (state, data) => {
  state.locationStates.data = data;
};
export const setCategories = (state, data) => {
  state.categories.data = data;
};
export const setDynamicFields = (state, data) => {
  state.dynamicFields.data = data;
};

export const setInvoicesLoading = (state, loading) => {
  state.invoices.loading = loading;
}

export const setInvoices = (state, data) => {
  const invoices = data;
  state.invoices.results = invoices.results;
  state.invoices.totalRows = invoices.count;
};

export const mutateInvoicePagination = (state, pagination) => {
  state.invoices.pagination = pagination;
};
export const mutateInvoiceFilters = (state, filters) => {
  state.invoices.filters = filters;
};

export const setSettlementsLoading = (state, loading) => {
  state.settlements.loading = loading;
}

export const setSettlements = (state, data) => {
  const settlements = data;
  state.settlements.results = settlements.results;
  state.settlements.totalRows = settlements.count;
};

export const mutateSettlementPagination = (state, pagination) => {
  state.settlements.pagination = pagination;
};
export const mutateSettlementFilters = (state, filters) => {
  state.settlements.filters = filters;
};

export const setItemsLoading = (state, loading) => {
  state.items.loading = loading;
}

export const setItems = (state, data) => {
  const items = data;
  state.items.results = items.results;
  state.items.totalRows = items.count;
};

export const mutateItemPagination = (state, pagination) => {
  state.items.pagination = pagination;
};
export const mutateItemFilters = (state, filters) => {
  state.items.filters = filters;
};

export const setTemporaryItemsLoading = (state, loading) => {
  state.temporaryItems.loading = loading;
}

export const setTemporaryItems = (state, data) => {
  const temporaryItems = data;
  state.temporaryItems.results = temporaryItems.results;
  state.temporaryItems.totalRows = temporaryItems.count;
};

export const mutateTemporaryItemPagination = (state, pagination) => {
  state.temporaryItems.pagination = pagination;
};
export const mutateTemporaryItemFilters = (state, filters) => {
  state.temporaryItems.filters = filters;
};

export const toggleSellAnItemModal = (state, show) => {
  state.sellAnItemModal.show = show;
}

export const setDepartmentsForSellAnItem = (state, data) => {
  state.departmentsForSellAnItem = data;
  data.forEach(department => {
    state.categoriesMap[department.key] = department.categories;
    department.categories.forEach(category => {
      const dynamicFields = [];
      for (let i in category.dynamic_fields) {
        dynamicFields.push({name: i, ...category.dynamic_fields[i]});
      }
      state.dynamicFieldsMap[category.key] = dynamicFields.sort((a, b) => a.position - b.position);
    });
  });
}

export const setError = (state, error) => {
  state.error = {...error};
}

export const setFiltersMenuOpenMobile = (state, val) => {
  document.body.style.overflow = val ? 'hidden' : 'auto';
  state.filtersMenuOpenMobile = val;
}

export const setHomeDepartmentsLoading = (state, loading) => {
  state.homePage.departments.loading = loading;
}

export const setHomeDepartments = (state, departments) => {
  state.homePage.departments.data = departments;
}

export const setPreserveOldAuctions = (state, preserve) => {
  state.lots.preserveOldAuctions = preserve
}

export const setPreserveOldLots = (state, preserve) => {
  state.lots.preserveOldLots = preserve
}

export const setCountries = (state, countries) => {
  state.countries = countries;
}

export const setTitles = (state, titles) => {
  state.titles = titles;
}

export const logoutUser = (state, preserve) => {
  Vue.cookie.delete(CONFIG.artisioCookieName);
  // state.socketSubscribedLots = {};
  state.user.token = null;
  state.customer = {};
  state.is_authorized = false;
  openLogin()
  afterLogout();
}

export const setLotBiddingStartedFinishedFlags = (state, {started, finished}) => {
  state.lotObject.biddingStarted = started;
  state.lotObject.biddingFinished = finished;
}

export const setShowMaxBidModal = (state, showModal) => {
  state.lotObject.maxBidModal = showModal
}

export const addIntoLastBids = (state, bid) => {
  const last_bids = state.lotObject.data.last_bids || [];
  const latestBid = {
    amount: bid.amount,
    auto_bid: bid.auto_bid,
    bid_date: bid.created_at,
    paddle_no: bid.paddle_no,
    uuid: bid.uuid,
  }
  state.lotObject.data = {
    ...state.lotObject.data,
    last_bids: [latestBid, ...last_bids].sort((a, b) => {
      if (a.amount > b.amount) {
        return -1;
      } else if (a.amount < b.amount) {
        return 1;
      } else {
        if (a.created_at < b.amount) {
          return -1;
        } else {
          return 1;
        }
      }
    })
  }
}

export const setSettings = (state, settings) => {
  state.settings = settings;
}

export const showUpcomingAmountPopover = (state, popover = null) => {
  const stateObjectName = popover['stateObjectName'];
  state.lotObject[stateObjectName] = Object.assign({}, state.lotObject[stateObjectName], popover);
  state.lotObject[stateObjectName].show = !state.lotObject[stateObjectName].show;
}

export const hideUpcomingAmountPopover = (state, stateObjectName) => {
  state.lotObject[stateObjectName] = Object.assign({}, state.lotObject[stateObjectName], {
    show: false,
    low: null,
    high: null
  });
}

export const setIsOnline = (state, isOnline) => {
  state.isOnline = isOnline
  const currentDate = Date.now();
  if (isOnline && state.lastOnlineTime && currentDate - state.lastOnlineTime > 15 * 1000) {
    state.showReloadMessage = true;
  }
  if (isOnline) {
    state.lastOnlineTime = currentDate;
  }
}

export const setShowReloadMessage = (state, show) => {
  state.showReloadMessage = show;
}

/**
 * This method will set given lot actual dates and return given lot
 * @param l
 * @returns {*}
 */
function setLotActualDates(l) {
  if (l.start_date) {
    let startEventTime = typeof l.start_date === "string" ? moment(l.start_date).toDate().getTime() : l.start_date.getTime();
    l.actual_start_date = moment(startEventTime).utc();
  }

  if (l.end_date) {
    let endEventTime = typeof l.end_date === "string" ? moment(l.end_date).toDate().getTime() : l.end_date.getTime();
    l.actual_end_date = moment(endEventTime).utc();
  }

  return l
}

export const signupFieldOnInput = (state, {field, value}) => {
  const fields = field.split('.');
  if (value === undefined) {
    value = '';
  }
  // This is object
  if (value && typeof value === 'object' && value.length === undefined) {
    value = {...value}
  }
  if (fields.length === 1) {
    state.signup.model = {
      ...state.signup.model,
      [field]: value
    }
    state.signup.model[field] = value;
  } else {
    state.signup.model[fields[0]][fields[1]] = value
  }
}

export const signupFieldErrors = (state, errors) => {
  try {
    const tmpErrors = {};
    for (let key in errors) {
      if (typeof errors[key] === 'string') {
        tmpErrors[key] = i18n.t(errors[key]);
      } else if (typeof errors[key] === 'object' && errors[key].length !== undefined) {
        tmpErrors[key] = errors[key].map(err => i18n.t(err));
      } else {
        tmpErrors[key] = errors[key]
      }
    }
    state.signup.errors = tmpErrors;
  } catch (e) {
    logger.error(e)
    state.signup.errors = errors;
  }
}

export const profileFieldOnInput = (state, {field, value}) => {
  const fields = field.split('.');
  if (value === undefined) {
    value = '';
  }
  // This is object
  if (value && typeof value === 'object' && value.length === undefined) {
    value = {...value}
  }
  if (fields.length === 1) {
    state.profile.model = {
      ...state.profile.model,
      [field]: value
    }
  } else {
    state.profile.model[fields[0]][fields[1]] = value
  }
}

export const resetProfileModel = (state) => {
  state.profile.model = JSON.parse(JSON.stringify(PROFILE_DEFAULT_VALUE))
  state.profile.errors = JSON.parse(JSON.stringify(PROFILE_DEFAULT_ERRORS))
}

export const setProfileModel = (state, profile) => {
  profile = JSON.parse(JSON.stringify(profile))
  state.profile.model.bank_account = profile.bank_account || {}

  Object.keys(state.profile.model).forEach(i => {
    state.profile.model[i] = !([undefined, null].includes(profile[i])) ? profile[i] : state.profile.model[i]
  });

  const addressObj = {'billing_address': 'billing', 'shipping_address': 'shipping'}
  if (profile.addresses) {
    for (const i in addressObj) {
      state.profile.model[i] = profile.addresses.filter(address => address.type === addressObj[i] && address.is_primary)[0] ?? state.profile.model[i];
      delete state.profile.model[i].is_primary;
      delete state.profile.model[i].type;
    }
  }

  state.profile.model = {
    ...state.profile.model
  }
}

export const profileFieldErrors = (state, errors) => {
  state.profile.errors = {...errors};
}

export const setSellAnItemModalFieldInput = (state, {field, value}) => {
  state.sellAnItemModal.model = {
    ...state.sellAnItemModal.model,
    [field]: value
  }
}


export const setSellAnItemErrors = (state, errors) => {
  state.sellAnItemModal.errors = {...errors};
}

export const resetSellAnItemErrors = (state, field) => {
  state.sellAnItemModal.errors = {
    ...state.sellAnItemModal.errors,
    [field]: null
  };

}

export const resetSellAnItemModel = (state) => {
  state.sellAnItemModal.model = {
    ...SELL_AN_ITEM_INITIAL_MODEL,
    images: [...SELL_AN_ITEM_INITIAL_MODEL.images]
  }
  // This is only necessary to listen in input components and do the action
  state.sellAnItemModal.flag = Math.floor(Math.random() * 10000000)
}

export const mutateSocketSubscribedLots = (state, lotsToSubscribe) => {
  // for (var index in lotsToSubscribe) {
  //   state.socketSubscribedLots[lotsToSubscribe[index]] = true;
  // }
}

export const clearSocketSubscribedLots = (state) => {
  // state.socketSubscribedLots = {};
}

export const updateSocketLastInteractionTime = (state) => {
  state.socketLastInteractionTime = new Date().getTime();
}

export const mutateAuction = (state, {data}) => {
  // Get auction from auction list
  let auctionListItem = state.auctions.results.find((auction) => auction.uuid === data.uuid);

  // If the updated auction is the auction which is opened at the moment we must updated opened auction details
  let auction = state.auctionObject.data;
  if (!auction.uuid) {
    auction = auctionListItem
  }
  data = {
    ...setLotActualDates(data)
  }
  if (auction && auction.uuid === data.uuid) {
    if (state.auctionObject.data.uuid) {
      state.auctionObject.data = {
        ...auction,
        ...data,
      }
    }

    // If there are any items in auction list.
    if (auctionListItem) {
      for (const key in data) {
        Vue.set(auctionListItem, key, data[key]);
      }
    }
  }

  // If lot view page is opened
  if (state && state.lotObject && state.lotObject.data && state.lotObject.data.auction_uuid === data.uuid) {
    state.lotObject.data = {
      ...state.lotObject.data,
      auction: {
        ...state.lotObject.data.auction,
        ...data
      }
    };
  }

  // Update auction if lot listing is opened
  state.lots.results = state.lots.results.map(lot => {
    if (lot.auction_uuid === data.uuid) {
      return {
        ...lot,
        auction: {
          ...lot.auction,
          ...data
        }
      };
    }
    return lot;
  });
}

export const setTermsAndConditionsModal = (state, modalData) => {
  state.termsAndConditionsModal = modalData;
}

export const setServerTime = (state, serverTime) => {
  state.clientTimeWhenServerResponded = Date.now()
  state.serverTime = serverTime
}

export const setAutoReorderMyBidsLots = (state, autoReorder) => {
  state.autoReorderMyBidsLots = autoReorder
  localStorage.setItem('ARTISIO_AUTO_REORDER_MY_BIDS_LOTS', autoReorder)
}
