<template>
  <div v-if="lots.loading" class="ta-lot-list" :class="['view-type-' + (lotListViewType)]">
    <div v-for="i in skeletonNumbers" v-bind:key="`skeleton-${i}`" class="mb-2">
      <div class="ta-lot-list-item">
        <div class="ta-lot-list-item-inner">

          <div class="ta-lot-list-item-thumb">
            <b-skeleton-img no-aspect width="240px" height="180px"></b-skeleton-img>
          </div>
          <div class="ta-lot-list-item-desc">
            <b-skeleton class="mb-2" animation="wave" width="100%" height="30px"></b-skeleton>
            <b-skeleton class="mb-2" animation="wave" width="200px"></b-skeleton>
            <b-skeleton class="mb-2" animation="wave" width="60px"></b-skeleton>
            <b-skeleton class="mb-2" animation="wave" width="80px"></b-skeleton>
            <b-skeleton class="mb-2" animation="wave" width="120px" height="30px"></b-skeleton>
          </div>
          <div class="ta-lot-list-item-buttons">
            <b-skeleton class="rounded-circle" animation="wave" width="30px" height="30px"></b-skeleton>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="lots.results.length">
    <offline-message />
    <div v-if="mainConfig.groupLotsOnMyBidsPage" class="ta-lots-grouped-by-auction">
      <div v-for="(auction, uuid) of lotsComputed" :key="uuid" class="mb-4">
        <h3 class="ta-lots-group-title"
             :class="auctionsVisible[uuid] ? null : 'collapsed'"
             :aria-expanded="auctionsVisible[uuid] ? 'true' : 'false'"
             @click="toggleAuction(auction)">
          {{ auction.auction_no }} - {{ auction.title }}
          <b-icon-chevron-up></b-icon-chevron-up>
        </h3>
        <hr>
        <b-collapse v-model="auctionsVisible[uuid]">
          <div class="ta-lot-list" :class="['view-type-' + (lotListViewType)]">
            <i-made-bid-lot-list-item v-for="lot in auction.lots" :key="lot.uuid + lot.hash" :lot="lot" />
          </div>
        </b-collapse>
      </div>
    </div>
    <div v-else class="ta-lot-list" :class="['view-type-' + (lotListViewType)]">
      <i-made-bid-lot-list-item v-for="lot in sortedLots" :key="lot.uuid + lot.hash" :lot="lot">
      </i-made-bid-lot-list-item>
    </div>
    <lot-list-load-more/>
    <div v-if="isPagingTypePagination" class="ta-lot-list-footer">
      <lot-list-pagination-summary/>
      <lot-list-pagination-buttons/>
    </div>
  </div>
  <div v-else>
    <p class="ta-no-lots-text">
      {{ $t('There are no lots') }}
    </p>
  </div>
</template>

<script>
import {mapGetters, mapState, mapMutations} from "vuex";
import LotListLoadMore from "./LotListLoadMore";
import IMadeBidLotListItem from "../../IMadeBidLotListItem.vue";
import lotAuctionListMixin from "@/mixins/lotAuctionListMixin";
import OfflineMessage from "@/components/OfflineMessage.vue";
import lotListFilterMixin from "@/mixins/lotListFilterMixin";

export default {
  name: "IMadeBidLotListItems",
  components: {OfflineMessage, IMadeBidLotListItem, LotListLoadMore},
  mixins: [lotListFilterMixin, lotAuctionListMixin('IMadeBidLotListItems')],
  data: () => ({
    auctionsVisible: {},
    lotsComputed: {},
    skeletonNumbers: []
  }),
  computed: {
    ...mapState(['lots', 'lotListViewType', 'mainConfig', 'lotQueryParams', 'autoReorderMyBidsLots']),
    ...mapState({
      contentLoaded: state => state.lots.loaded // Necessary for mixin
    }),
    ...mapGetters(['isPagingTypeLoadMore', 'isPagingTypePagination']),
    sortedLots() {
      if (this.autoReorderMyBidsLots) {
        const sortBy = (this.fields.queryParams['sort-by'] || 'asc').toLowerCase();
        return this.sortLots(this.lots.results, sortBy);
      }
      return [...this.lots.results];
    }
  },
  watch: {
    'lots.loading': {
      handler(newVal, oldVal) {
        if (!newVal && oldVal) {
          const groupedLots = {};

          this.lots.results.forEach(item => {
            if (!groupedLots[item.auction_uuid]) {
              groupedLots[item.auction_uuid] = {
                uuid: item.auction_uuid,
                auction_no: item.auction_no,
                title: this.$options.filters.currentLocale(item.auction_title),
                visible: true,
                lots: []
              }
              this.auctionsVisible[item.auction_uuid] = true
            }
            groupedLots[item.auction_uuid].lots.push(item)
          });

          this.auctionsVisible = {
            ...this.auctionsVisible
          }

          this.lotsComputed = groupedLots;
        }
      },
      immediate: true
    },
    'autoReorderMyBidsLots': {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && !newVal) {
          const lots = {...this.lots}
          const sortedLots = this.sortLots(lots.results, 'asc')
          this.emptyLots()
          this.setLots({
            ...lots,
            results: sortedLots
          })
        }
      }
    }
  },
  methods: {
    ...mapMutations(['emptyLots', 'setLots']),
    toggleAuction(auction) {
      this.auctionsVisible = {
        ...this.auctionsVisible,
        [auction.uuid]: !this.auctionsVisible[auction.uuid]
      }
    },
    sortLots(lots, sortBy = 'asc') {
      lots = [...lots]
      return lots.sort((a, b) => {
        // Sort by end_date
        if (sortBy === 'desc')
          return new Date(b.end_date) - new Date(a.end_date);

        return new Date(a.end_date) - new Date(b.end_date)
      })
    }
  },
  mounted() {
    for (let i = 0; i < this.lotQueryParams.limit; i++) {
      this.skeletonNumbers.push(i)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../bootstrap/import.scss";

.ta-no-lots-text {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: $gray-600
}

.ta-lot-list {
  display: grid;
  grid-template-columns: 1fr;

  &.view-type-grid {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
  }
}

#artisioTimedAuctions {
  .ta-lots-group-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-size: 1.25rem;

    > svg {
      transition: transform 0.3s;
    }

    &.collapsed {
      > svg {
        transform: rotate(180deg);
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  .ta-lot-list.view-type-grid {
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  }
}

@include media-breakpoint-down(md) {
  .ta-lot-list {
    flex-direction: column;
  }
}

@include media-breakpoint-down(xs) {
  .ta-lot-list {
    column-gap: 1rem;
    row-gap: 1rem;
  }
}
</style>
