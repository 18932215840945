import {getLotsQuery} from "@/helpers";
import {mapState} from "vuex";

export default {
  computed: {
    ...mapState(['customer', 'mainConfig']),
    lotInnerPageRoute() {
      if (this.$route.name === 'PrivateTreatyLots') {
        return {
          name: 'PrivateTreatyLotView',
          params: {lotno: this.lot.uuid}
        }
      }
      const query = getLotsQuery();
      if (this.$route.name === 'IMadeBidLots') {
        query.i_made_bid = 1;
        query.grouped_by_auction = this.mainConfig.groupLotsOnMyBidsPage ? 1 : 0;
      } else if (this.$route.name === 'FavoriteLots') {
        query.favorites = 1;
        query.grouped_by_auction = this.mainConfig.groupLotsOnFavouritesPage ? 1 : 0;
      }
      return {name: 'LotView', params: {lotno: this.lot.uuid}, query}
    },
    title() {
      return this.$options.filters.stripTags(this.$options.filters.currentLocale(this.lot.title)).trim().replace(/^&nbsp;/, '')
    },
    isCurrentUserLastBidder() {
      if (this.customer?.uuid && this.lot.winning_bid?.customer_uuid) {
        return this.customer.uuid === this.lot.winning_bid.customer_uuid;
      }
      return false;
    },
    reserveMet() {
      if (!this.lot) return false;
      return this.lot.winning_bid && (!this.lot.reserve || parseFloat(this.lot.reserve) <= parseFloat(this.lot.winning_bid.amount))
    },
    /**
     * When reserve and winning_bid exists, but reserve is more than winning_bid.amount
     * @returns {null|*|boolean}
     */
    reserveNotMet() {
      return this.lot.reserve && this.lot.winning_bid && this.lot.reserve > this.lot.winning_bid.amount
    },
  },
}
