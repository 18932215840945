<template>
  <b-pagination
    v-if="isPagingTypePagination && lots.totalRows > fields.queryParams.limit"
    v-model="page"
    :total-rows="lots.totalRows"
    :per-page="fields.queryParams.limit"
    @input="updatePagination"
    @page-click="onPageClick"
    class="ta-lot-list-pagination"
  ></b-pagination>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {LOT_LIST_DEFAULT_PAGE} from "../../../constants";
import lotListFilterMixin from "../../../mixins/lotListFilterMixin";

export default {
  name: "LotListPaginationButtons",
  mixins: [lotListFilterMixin],
  data: () => ({
    page: LOT_LIST_DEFAULT_PAGE,
  }),
  computed: {
    ...mapState(['lots', 'mainConfig']),
    ...mapGetters(['isPagingTypePagination']),
  },
  methods: {
    updatePagination() {
      this.fields.setFilters({page: this.page});
      this.fields.updateRoute();
    },
    onPageClick() {
      if (this.mainConfig.scrollToTopOnPageChange) {
        localStorage.setItem('artisio.dontRecoverScroll', '1')
        this.$nextTick(() => {
          setTimeout(() => {
            const el = document.querySelector('.ta-lots-and-filters')
            el.scrollIntoView({behavior: 'smooth'});
          }, 1000)
        })
      }
    }
  },
  mounted() {
    this.page = this.fields.queryParams.page;
  }
}
</script>

<style scoped>

</style>
