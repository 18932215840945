<template>
  <a href="javascript:void(0)" @click="onSignup($event)" class="ta-link-to-signup">
    <slot/>
  </a>
</template>

<script>
import {openSignup} from "@/helpers";

export default {
  name: "LinkToSignup",
  methods: {
    onSignup(ev) {
      openSignup(ev)
    }
  }
}
</script>

<style scoped>

</style>
