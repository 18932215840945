<template>
  <vue-bootstrap-autocomplete
    :data="foundLots"
    v-model="lotQuery"
    :maxMatches="5"
    :minMatchingChars="1"
    :disableSort="true"
    :inputClass="inputClass"
    :serializer="lot => lot.lot_no + ' ' + $options.filters.stripTags($options.filters.currentLocale(lot.title))"
    :placeholder="$t('Type and search for lots...')"
    @hit="onLotSelect"
    @input="debouncedLookupLots"
    :no-results-info="$t('No results found')"
  >
    <!-- htmlText is bound to the matched text derived from the serializer function -->
    <!-- data is bound to the matching array element in the data prop -->
    <template slot="suggestion" slot-scope="{ data: lot }">
      <div class="ta-lot-type-ahead-suggestion">
        <b-img v-if="lot.image_urls.length" :src="lot.image_urls[0]"
               width="48"
               :alt="$options.filters.stripTags($options.filters.currentLocale(lot.title))"/>
        <b-img v-else src="https://cdn.artisio.co/no_image.jpg" width="48"/>
        <div>
          <h5 class="ta-suggestion-lot-no"><small>{{ $t('Lot No') }}: {{lot.auction_no}}/{{ lot.lot_no}}</small></h5>
          <div class="ta-suggestion-lot-title">{{ $options.filters.stripTags($options.filters.currentLocale(lot.title)) }}</div>
        </div>
      </div>
    </template>
  </vue-bootstrap-autocomplete>
</template>

<script>
import axiosClient from "@/services/axios";

export default {
  name: "LotTypeAhead",
  props: {
    auctionUuid: {
      type: String,
    },
    inputClass: {
      type: String,
      default: ''
    },
  },
  data: () => ({
    foundLots: [],
    lotQuery: null,
    selectedLot: null,
    debounceTimeout: null
  }),
  methods: {
    onLotSelect($event) {
      this.$emit('input', $event)
    },
    lookupLots () {
      axiosClient.get(`website/lots/typeahead/`, {
        params: {
          auction_uuid: this.auctionUuid,
          keyword: this.lotQuery
        }
      })
        .then(({data, status}) => {
          console.log(data.lots)
          this.foundLots = data.lots
          // this.foundLots = ['Canada', 'USA', 'Mexico']
        });
    },
    debouncedLookupLots () {
      clearTimeout(this.debounceTimeout); // clear any previously set timeout
      this.debounceTimeout = setTimeout(() => {
        this.lookupLots();
      }, 300); // Set debounce delay (e.g., 300ms)
    },
    reset() {
      this.lotQuery = null;
    }
  }
}
</script>

<style lang="scss" scoped>
  #artisioTimedAuctions {
    .ta-lot-type-ahead-suggestion {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    .ta-suggestion-lot-no {
      margin-bottom: 5px;
      font-size: 95%;
    }
    .ta-suggestion-lot-title {
      margin: 0;
      font-size: 90%;
    }
  }
  /deep/ .list-group-item {
    padding: 0.25rem !important;
  }
</style>
