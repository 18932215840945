<template>
  <b-button
    :type="type"
    :disabled="disabled || loading"
    :block="block"
    :variant="variant"
    @click="$emit('click', $event)"
  >
    <span v-if="loading" :style="{'position': absolute ? 'absolute': 'static'}">
      <b-spinner small class="mr-3" label="Spinning"></b-spinner>
    </span>
    <slot></slot>
  </b-button>
</template>

<script>
export default {
  name: 'LoadingButton',
  props: {
    loading: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'submit'
    },
    block: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: 'primary'
    },
    absolute: {
      type: Boolean,
      default: true,
    }
  }
};
</script>

<style lang="scss" scoped>
button {
  position: relative;
  > span {
    position: absolute;
    left: 1rem;
  }
}
</style>
