<template>
  <div v-if="show" class="ta-lot-list-auto-update-checkbox">
    <b-form-checkbox v-model="autoUpdate" @change="setAutoUpdate()">
      {{ $t("Auto Reorder") }}
      <b-icon id="autoUpdateLots" icon="question-circle" />
      <b-tooltip target="autoUpdateLots" container="artisioTimedAuctions">
        {{ $t('Lots will be automatically reordered when lot end dates changed because of last minute bidding.') }}
      </b-tooltip>
    </b-form-checkbox>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import lotListFilterMixin from "@/mixins/lotListFilterMixin";

export default {
  name: "LotListAutoUpdateCheckbox",
  mixins: [lotListFilterMixin],
  data: () => ({
    autoUpdate: false
  }),
  computed: {
    ...mapState(['autoReorderMyBidsLots']),
    show() {
      const sort = this.fields.queryParams.sort;

      return sort === 'end_date';
    }
  },
  methods: {
    ...mapMutations(['setAutoReorderMyBidsLots']),
    setAutoUpdate() {
      this.setAutoReorderMyBidsLots(this.autoUpdate)
    }
  },
  mounted() {
    const autoUpdate = localStorage.getItem('ARTISIO_AUTO_REORDER_MY_BIDS_LOTS')
    if (autoUpdate === 'true') {
      this.autoUpdate = true;
      this.setAutoUpdate()
    }
  }
}
</script>

<style lang="scss" scoped>
  .ta-lot-list-auto-update-checkbox {
    margin-right: 20px;
  }
</style>
