<template>
  <b-modal
      v-if="mainConfig.termsAndConditionsHtml || mainConfig.termsAndConditionsUrl"
      ref="modal"
      v-model="termsAndConditionsModal.show"
      id="terms_and_conditions_modal"
      :title="$t('Terms and Conditions')"
      modal-class="modal fade show block"
      @hidden="hideModal"
      role="dialog"
      size="lg"
      :no-close-on-backdrop="termsAndConditionsModal.forceToAccept"
      :no-close-on-esc="termsAndConditionsModal.forceToAccept"
      :hide-header-close="termsAndConditionsModal.forceToAccept"
      :ok-title="termsAndConditionsModal.forceToAccept ? $t('Accept') : $t('Close')"
      ok-only
      @ok="acceptTerms"
      :ok-disabled="loading"
      static
      lazy
  >
    <div v-if="mainConfig.termsAndConditionsHtml" v-html="mainConfig.termsAndConditionsHtml"></div>
    <div v-else-if="mainConfig.termsAndConditionsUrl" class="mb-4">
      <p>
        {{ $t('Please read and accept terms and conditions on the following link - ') }}
        <a :href="mainConfig.termsAndConditionsUrl" target="_blank">{{ $t('Terms and Conditions') }}</a>
      </p>

    </div>

    <template v-if="termsAndConditionsModal.forceToAccept">
      <b-form-checkbox v-model="accept">
        <span v-html="$t('I read and accept terms and conditions above')"></span>
      </b-form-checkbox>
      <p v-if="errorMessage" class="text-danger">{{ $t('Please accept terms and conditions above') }}</p>
    </template>
  </b-modal>
</template>

<script>
import {mapState} from 'vuex'
import axiosClient from "@/services/axios";


export default {
  name: "TermsAndConditionsModal",
  data() {
    return {
      loading: false,
      errorMessage: false,
      successMessage: false,
      accept: ''
    }
  },
  computed: {
    ...mapState(['termsAndConditionsModal', 'mainConfig'])
  },
  methods: {
    acceptTerms($event) {
      if (!this.termsAndConditionsModal.forceToAccept) {
        return;
      }

      $event.preventDefault();
      if (this.termsAndConditionsModal.forceToAccept && !this.accept) {
        this.errorMessage = true;
        return;
      }

      this.loading = true;
      axiosClient.post('/website/accept-terms-and-conditions/', {
        version: this.mainConfig.termsAndConditionsVersion
      })
          .then(({data}) => {
            if (data.success) {
              setTimeout(() => {
                window.location.reload()
              }, 1000)
            } else {
              this.loading = false;
            }
          })
          .catch((err) => {
            console.error(err.message)
            this.loading = false;
          })
    },
    hideModal() {

    }
  },
}
</script>
