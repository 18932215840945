<template>
  <div class="ta-auth-card ta-request-password-form">
    <ValidationObserver v-slot="{ invalid }">
      <form @submit.prevent="onSubmit">
        <div class="text-center">
          <h3 class="mb-3">
            {{ $t("Forgot Your Password?") }}
          </h3>
        </div>

        <FormAlert :success-message="successMessage" :error-message="errorMessage"></FormAlert>
        <ResendEmail :email="model.email" v-model="resend"/>

        <input-widget
          v-model="model.email"
          :error="errors.email"
          id="input-email"
          type="email"
          :label="$t('Email Address')"
          rules="required|email"
        />

        <div class="mb-3 d-flex justify-content-between">
          <link-to-login>{{ $t("Back to Login") }}</link-to-login>
          <link-to-signup>{{ $t("Signup Now") }}</link-to-signup>
        </div>

        <LoadingButton block :loading="loading" :disabled="invalid">
          {{ $t("Request New Password") }}
        </LoadingButton>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import LoadingButton from "../../components/core/LoadingButton.vue";
import InputWidget from "../../components/core/InputWidget.vue";
import FormAlert from "../../components/core/FormAlert";
import ResendEmail from "../../components/core/ResendEmail";
import i18n from "@/i18n";
import LinkToLogin from "@/components/configuration/signup/LinkToLogin.vue";
import LinkToSignup from "@/components/configuration/signup/LinkToSignup.vue";

export default {
  name: "ForgotPassword",
  data() {
    return {
      loading: false,
      successMessage: "",
      errorMessage: "",
      model: {
        email: "",
      },
      errors: {
        email: null,
      },
      resend: {
        messageCode: "",
        secondsLeft: null
      }
    };
  },
  methods: {
    ...mapActions(["requestPasswordReset"]),
    onSubmit() {
      this.loading = true;
      this.requestPasswordReset(this.model.email)
        .then((res) => {
          this.loading = false;
          this.successMessage = i18n.t(res.message);
          this.errorMessage = "";
          this.resend.messageCode = "";
          this.resend.secondsLeft = null;
          if (res.message_code) {
            this.resend.messageCode = res.message_code;
            this.resend.secondsLeft = res.seconds_left;
          }
        })
        .catch((msg) => {
          this.loading = false;
          this.successMessage = "";
          this.errorMessage = i18n.t(msg);
        });
    },
  },
  components: {LinkToSignup, LinkToLogin, FormAlert, ResendEmail, LoadingButton, InputWidget },
};
</script>

<style lang="scss" scoped></style>
