<template>
  <div>
    <div v-if="stripeElementsOptions.clientSecret">
      <div class="stripe-amount">{{ stripeAmount }}</div>
      <form id="payment-form" @submit.stop style="text-align: center">
        <stripe-element-payment
            ref="stripePaymentRef"
            :pk="settings.stripe_public_key"
            :elements-options="stripeElementsOptions"
            redirect="if_required"
            :confirm-params="stripeConfirmParams"
            @confirmed="stripePaymentConfirmed"
        />
        <b-button variant="primary" @click="$refs.stripePaymentRef.submit()" style="margin-top: 2rem" block
                  class="mr-3 ta-btn-submit ta-signup-payment-button">
          {{ $t('Pay Now') }}
        </b-button>
      </form>
    </div>
  </div>

</template>


<script>
import {StripeElementPayment} from "@vue-stripe/vue-stripe";
import Stripe from "@/services/stripe";
import {asCurrency} from "@/filters/currency";
import {mapState} from "vuex";

export default {
  name: "StripePaymentForm",
  components: {StripeElementPayment},
  data: () => ({
    stripeElementsOptions: {loader: 'always', clientSecret: null},
    stripeConfirmParams: {return_url: ''},
    stripeAmount: null,
  }),
  computed: {
    ...mapState({
      'settings': state => state.settings
    }),
  },
  methods: {
    stripePaymentConfirmed(event) {
      Stripe.getTransaction(event.id, (err, transaction) => {
        if (err) {
          console.error(err);
          this.$emit('stripe-error', err)
          return
        }
        console.log(transaction);
        this.$emit("stripe-transaction-complete", event.id);
      })
    },
  },
  mounted() {
    const amount = parseFloat(this.settings.payment_on_registration_amount);
    Stripe.createTransaction(amount, (err, transaction) => {
      this.$emit('stripe-loaded')
      if (err) {
        console.error(err);
        this.$emit('stripe-error', err)
        return;
      }
      this.stripeAmount = this.$i18n.t('{value} fee is required in order to complete registration.',
          {value: asCurrency(transaction.amount / 100, transaction.currency)})
      this.stripeElementsOptions.clientSecret = transaction.client_secret
    });
  }
}
</script>


<style lang="scss">
.stripe-amount {
  text-align: center;
  background-color: #fff3cd;
  border: 1px solid #ffe799;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-radius: 4px;
  margin-bottom: 1rem;
}

#stripe-payment-element-errors:not(:empty) {
  text-align: center;
  background-color: #f8d7da;
  border: 1px solid #ffb3b8;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-radius: 4px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

</style>