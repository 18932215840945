<template>
  <filter-group v-if="show" :title="$t('Auction Type')"
                id="collapse-auction-type"
                class="ta-filter-group"
                :collapsed="collapsed"
  >
    <b-form-radio name="auction-type" v-model="model" @change="filtersChanged" value="all">
      {{ $t('All') }}
    </b-form-radio>
    <b-form-radio name="auction-type" v-model="model" @change="filtersChanged" value="timed">
      {{ $t('Timed') }}
    </b-form-radio>
    <b-form-radio name="auction-type" v-model="model" @change="filtersChanged" value="live">
      {{ $t('Live') }}
    </b-form-radio>
  </filter-group>
</template>

<script>
import FilterGroup from "../../FilterGroup";
import {mapMutations, mapState} from "vuex";
import {LOT_LIST_DEFAULT_PAGE} from "../../../constants";
import {updateLotsRoute} from "../../../helpers";
import userConfig from "@/user-config";

export default {
  name: "FilterAuctionType",
  components: {FilterGroup},
  props: {
    collapsed: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    model: 'all',
  }),
  computed: {
    ...mapState(['lotQueryParams']),
    show() {
      return !['AuctionViewTimed', 'PrivateTreatyAuctionView', 'AuctionViewCatalog'].includes(this.$route.name);
    }
  },
  watch: {
    lotQueryParams() {
      this.model = this.lotQueryParams.auction_type;
    },
  },
  methods: {
    ...mapMutations(['setLotFilters']),
    filtersChanged() {
      this.setLotFilters({
        auction_type: this.model,
        page: LOT_LIST_DEFAULT_PAGE,
      });
      if (userConfig.autoApplyFilters) { // TODO We need to also check if the filters are active on mobile devices
        updateLotsRoute();
      }
    }
  },
  mounted() {
    this.model = this.lotQueryParams.auction_type ?? 'all';
  }
}
</script>

<style scoped>

</style>
