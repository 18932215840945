<template>
  <div v-if="payment_on_registration" class="ta-signup-payment-form">
    <div v-if="loading" class="ta-signup-payment-loader">
      <BSpinner></BSpinner>
    </div>
    <div v-if="transactionComplete" class="ta-signup-payment-success-notification">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" style="width: 96px" viewBox="0 0 24 24" stroke-width="1.5"
           stroke="currentColor"
           class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round"
              d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
      </svg>
      <div class="ta-payment-success-text">
        {{ $t('Your payment has been accepted.') }}
      </div>
    </div>
    <div v-else-if="timeout" class="ta-signup-payment-timeout-notification text-center">
      <h3 class="mb-4">{{ $t('Your payment link was expired') }}</h3>
      <b-button @click="reloadPage()" >
        {{$t('Please reload the page')}}
      </b-button>

    </div>
    <div v-else-if="settings.payment_on_registration_provider === 'paystation'" ref="_paymentFrameWrapper"
         class="ta-registration-payment-iframe-wrapper">
    </div>
    <div v-else-if="settings.payment_on_registration_provider === 'stripe'" class="ta-registration-payment-iframe-wrapper">
      <stripe-payment-form
          @stripe-transaction-complete="(transaction_id) => {this.transactionComplete=true; $emit('on-payment-complete', transaction_id)}"
          @stripe-error="(error) => {errorMessage=error}"
          @stripe-loaded="loading=false"/>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import Paystation from "@/services/payStation";
import StripePaymentForm from "@/components/StripePaymentForm.vue";

export default {
  name: "RegistrationPaymentForm",
  components: {
    StripePaymentForm
  },
  props: {
    formIsValid: Boolean
  },
  data: () => ({
    loading: true,
    errorMessage: null,
    timeout: false,
    transactionComplete: false,
  }),
  computed: {
    ...mapState({
      'settings': state => state.settings,
      'payment_on_registration': state => state.settings && parseInt(state.settings.payment_on_registration)
    })
  },
  methods: {
    payHere() {
      const amount = parseFloat(this.settings.payment_on_registration_amount);

      if (this.settings.payment_on_registration_provider === 'paystation') {

        // create the transaction, get the URL for iframe, show it to the user, begin polling the transaction to see when they have finished paying.
        Paystation.createTransaction(amount, (err, transaction) => {
          if (err) {
            this.errorMessage = err;
            return;
          }

          this.openPaymentForm(transaction.transactionId, transaction.digitalOrderUrl);
        });
      } else if (this.settings.payment_on_registration_provider !== 'stripe') {
        console.error('Unsupported "payment_on_registration_provider"')
      }
    },

    openPaymentForm(txnId, paymentUrl) {
      // window.localStorage.setItem('txnId', txnId);
      // window.localStorage.setItem('txnUrl', paymentUrl);
      // showQrCode(paymentUrl);

      this.transactionComplete = false;
      this.$refs._paymentFrameWrapper.innerHTML = '';
      const _paymentFrame = Paystation.createPaymentFrame(this.$refs._paymentFrameWrapper, paymentUrl, this.onFrameLoaded.bind(this), true);
      _paymentFrame.classList.add('paystation-payment-frame');
      Paystation.pollTransactionDetails(txnId, this.onTransactionResponse.bind(this));
    },

    // // This gets called whenever a new page is loaded in the iframe. This is only possible if you are using redirects.
    onFrameLoaded(iframe) {
      this.loading = false;
      // Browsers shouldn't allow access to the content of an iframe unless it is from your own domain.
      // So, if we can access the content, then the user has been redirected back to your site from paystation.
      if (Paystation.canAccessIFrame(iframe)) {
        // We have redirected back to our own site inside the iframe.
        // It is possible to grab some data from inside the frame, but it is better and often quicker to use polling to get this response as that data can be trusted.
        Paystation.closePaymentFrame(iframe);
      }
    },
    //
    // // This function will get a response every time we poll the website.
    // // Most of these responses will get transaction details for an incomplete transaction while the user is still entering their details in the iframe.
    onTransactionResponse(err, transaction) {
      if (err) {
        console.error(err);
        // have some error handling if you want
        this.errorMessage = err;
        return;
      }

      if (transaction.timeout) {
        this.timeout = true;
        this.$refs._paymentFrameWrapper.innerHTML = '';
        Paystation.stopPolling();
        return;
      }
      console.log(transaction);
      // hasError is for all errors regardless if they come from paystation or us, which could happen before the transaction completes.
      // errorCode is a paystation response which is set after a transaction is complete. A negative error code means no error code has been returned.
      if (transaction && (transaction.errorCode === 0 && !transaction.hasError)) {
        this.onTransactionComplete(transaction);
      }
    },
    //
    // // Remove the iframe and stop polling the transaction details. Show a response to the user.
    onTransactionComplete(transaction) {
      if (this.transactionComplete) {
        return;
      }
      this.transactionComplete = true;

      window.localStorage.removeItem('txnId');
      window.localStorage.removeItem('txnUrl');
      window.localStorage.setItem('taSignupTransactionId', transaction.transactionId);
      this.$refs._paymentFrameWrapper.innerHTML = '';
      Paystation.stopPolling();
      this.$emit("on-payment-complete", transaction.transactionId);

    },

    loadPreviousTransaction() {
      const txnId = window.localStorage.getItem('txnId');
      const txnUrl = window.localStorage.getItem('txnUrl');
      if (txnId && txnUrl) {
        this.openPaymentForm(txnId, txnUrl);
        return true
      }
      return false;
    },
    reloadPage() {
      window.location.reload()
    }
  },
  mounted() {
    const transactionId = window.localStorage.getItem('taSignupTransactionId');
    if (transactionId) {
      this.transactionComplete = true;
      this.$emit("on-payment-complete", transactionId);
    } else {
      this.payHere()
    }
  }
}
</script>

<style lang="scss" scoped>
#artisioTimedAuctions {
  .ta-signup-payment-success-notification {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #36b15f;
  }

  .ta-payment-success-text {
    font-size: 28px;
  }

  .ta-signup-payment-form {
    min-height: 100px;
    position: relative;
  }

  .ta-signup-payment-loader {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ta-signup-payment-amount {
    text-align: center;
    font-size: 48px;
  }

  .ta-signup-payment-button {
    text-align: center;
    margin-bottom: 1rem;
  }
}
</style>
