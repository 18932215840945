import Vue from 'vue'
import i18n from "../i18n";
import axiosClient from "../services/axios";
import logger from "../logger";
import CONFIG from '../user-config'
import {
  afterLogin,
  getCurrentServerTime,
  getVuexAuctionListFilters,
  getVuexFavoriteAuctionListFilters,
  getVuexLotListFilters,
  hideHTMLElements,
  PreciseNum,
  showHTMLElements
} from '../helpers';
import router from "../router";
import {asCurrency} from "../filters/currency";
import moment from "moment";

export async function getAuctions({getters, state, commit, dispatch}) {
  commit('setAuctionsLoading', true);

  const params = getVuexAuctionListFilters();

  let url = `website/auctions/timed`;
  switch (router.app.$route.name) {
    case 'PrivateTreatyAuctions':
      url += `?private_treaty=true`;
      break;
    default:
  }
  const {data, status} = await axiosClient.get(url, {
    // params: {auction_uuid, ...pagination, ...filters}
    params
  });
  if (status === 200) {
    // If according to user config the pagination type is set to buttons, we clear previous data
    if (!state.auctions.preserveOldAuctions) {
      commit('emptyAuctions');
    }
    commit('setPreserveOldAuctions', false);
    commit('setAuctions', data.auctions);
    commit('setAuctionsLoading', false);
    commit('setAuctionsLoaded', true);
  } else {
    logger.error(`Auction data was not fetched`, data);
  }
}

export async function getFavoriteAuctions({commit}) {
  commit('setAuctionsLoading', true);

  const params = getVuexFavoriteAuctionListFilters();

  let url = `website/auctions/timed`;
  const {data, status} = await axiosClient.get(url, {
    params: {
      ...params,
      "favorites": 1,
      "status": "all"
    }
  });
  if (status === 200) {
    commit('setAuctions', data.auctions);
    commit('setAuctionsLoading', false);
    commit('setAuctionsLoaded', true);
  } else {
    logger.error(`Auction data was not fetched`, data);
  }
}

// export async function getAuctions({commit, state}, {pagination, filters = null}) {
//   commit('setAuctionsLoading', true)
//   commit('mutateAuctionPagination', pagination);
//   commit('mutateAuctionFilters', filters);
//
//   const {data, status} = await axiosClient.get('website/auctions/timed/', {
//     params: {...pagination, ...filters}
//   });
//   // router.push({name: router.currentRoute.name, query: {...state.auctions.pagination, ...state.auctions.filters.urlParams}});
//   commit('setAuctionsLoading', false)
//
//   if (status === 200) {
//     commit('setAuctions', data);
//   } else {
//     logger.error("Auctions were not fetched ", data);
//   }
// }

export async function getAuctionData({commit}, uuid) {
  commit('setAuctionObjectLoading', true);
  try {
    const {data, status} = await axiosClient.get(`website/auctions/timed/${uuid}`)
    if (status === 200) {
      commit('setAuctionObjectData', data);
      commit('setAuctionObjectLoading', false);
    } else {
      logger.error(`Auction data was not fetched for uuid: ${uuid}`, data);
    }
  } catch (e) {
    commit('setAuctionObjectLoading', false);
  }
}

export async function getLots({getters, state, commit, dispatch}, {stopLoading = false} = {}) {
  if (!stopLoading) {
    commit('setLotsLoading', true);
  }

  let params = JSON.parse(JSON.stringify(getVuexLotListFilters()));

  let url = `website/lots/`;

  delete params.i_made_bid
  delete params.favorites
  switch (router.app.$route.name) {
    case 'PrivateTreatyLots':
    case 'PrivateTreatyAuctionView':
      url += `?private_treaty=true`;
      break;
    case 'FavoriteLots':
      // delete params['lot_status'];

      params.favorites = 1

      if (state.mainConfig.groupLotsOnFavouritesPage) {
        params.grouped_by_auction = 1
      } else {
        params.grouped_by_auction = 0
      }
      // url += `?favorites=1&lot_status=all`;
      break;
    case 'IMadeBidLots':
      params.i_made_bid = 1

      if (state.mainConfig.groupLotsOnMyBidsPage) {
        params.grouped_by_auction = 1
      } else {
        params.grouped_by_auction = 0
      }
      break;
    default:
  }

  if (params['sold_to_me'] === 'yes' && params['lot_status'] !== 'sold') {
    delete params['sold_to_me'];
  }
  const {data, status} = await axiosClient.get(url, {
    // params: {auction_uuid, ...pagination, ...filters}
    params
  });
  if (status === 200) {
    // If according to user config the pagination type is set to buttons, we clear previous data
    if (!state.lots.preserveOldLots) {
      commit('emptyLots');
    }
    commit('setPreserveOldLots', false);
    commit('setLots', data.lots);
    dispatch('subscribeToLots', state.lots.results.filter(l => l.status === 'published' || l.auction.status === 'published').map(lot => lot.uuid));
    commit('setLotsLoading', false);
    commit('setLotsLoaded', true);
  } else {
    logger.error(`Lot data was not fetched`, data);
  }
}

export async function getLotData({state, commit, dispatch}, uuidOrObj) {
  let stopLoading = false;
  let uuid = uuidOrObj;
  if (typeof uuidOrObj === 'object') {
    uuid = uuidOrObj.uuid;
    stopLoading = uuidOrObj.stopLoading
  }
  if (!stopLoading) {
    commit('setLotObjectLoading', true);
    commit('setAuctionObjectLoading', true);
  }
  const params = getVuexLotListFilters();
  let url = `website/lots/${uuid}`
  switch (router.app.$route.name) {
    case 'PrivateTreatyLotView':
      url += `?private_treaty=true`;
      break;
    default:
  }
  try {
    const {data, status} = await axiosClient.get(url, {params});
    if (status === 200) {
      commit('setLotObjectData', data);
      commit('setAuctionObjectData', data.auction);
      commit('setAuctionObjectLoading', false);
      commit('setLotObjectLoading', false);
      if (data.status === 'published') {
        dispatch('getLotDataSocket', [data.uuid])
      }
      return {data, status}
    } else {
      logger.error(`Lot data was not fetched for uuid: ${uuid}`, data);
      return {data, status}
    }

  } catch (e) {
    const {status} = e.response;
    return {status}
  }
}

export async function loadLotBids({state, commit, dispatch}) {
  if (!state.lotObject || !state.lotObject.data) {
    return
  }
  const params = {};
  if (state.lotObject.data.last_bids && state.lotObject.data.last_bids.length) {
    params.date = state.lotObject.data.last_bids[state.lotObject.data.last_bids.length - 1].raw_created_at;
    params.lot_uuid = state.lotObject.data.uuid
  }
  const {data, status} = await axiosClient.get('website/bids', {params});
  if (status === 200) {
    commit('expandLotLastBids', data.data);
    return data.data;
  } else {
    logger.error(`Lot Last bids load more was not properly fetched for uuid: ${params.lot_uuid}`, data);
    return null;
  }
}

export async function getDepartments({state, getters, commit}) {
  commit('setDepartmentsLoading', true);
  commit('setLocationStatesLoading', true);

  let params = {...getVuexLotListFilters()};
  delete params.page;
  delete params.limit;
  delete params.sort;
  delete params['sort-by'];

  switch (router.app.$route.name) {
    case 'IMadeBidLots':
      params.i_made_bid = 1

      if (state.mainConfig.groupLotsOnMyBidsPage) {
        params.grouped_by_auction = 1
      } else {
        params.grouped_by_auction = 0
      }
      break;
    case 'FavoriteLots':
      params.favorites = 1

      if (state.mainConfig.groupLotsOnFavouritesPage) {
        params.grouped_by_auction = 1
      } else {
        params.grouped_by_auction = 0
      }
      break;
    default:
  }

  try {
    const {data, status} = await axiosClient.get(`website/departments`, {
      params
    })

    if (status === 200) {
      commit(`setDepartments`, data.departments);
      commit('setDepartmentsLoading', false);

      commit(`setLocationStates`, data.location_states);
      commit('setLocationStatesLoading', false);

      const selectedDept = getters.selectedDepartment;
      if (selectedDept) {
        commit('setCategories', selectedDept.categories);
        commit('setDynamicFields', selectedDept.dynamic_fields_template);
      } else if (state.mainConfig.alwaysShowCategories) {
        const allCategories = state.departments.data.reduce((accum, dep) => [...accum, ...dep.categories], [])
        commit('setCategories', allCategories);
      }
    }
  } catch (e) {
    commit(`setDepartments`, []);
    commit('setDepartmentsLoading', false);

    commit(`setLocationStates`, []);
    commit('setLocationStatesLoading', false);
    logger.error(`Error while getting departments`, e)
  }
}

export async function getDepartmentsForAuctions({state, getters, commit}) {
  commit('setDepartmentsLoading', true);

  const params = {...getVuexAuctionListFilters()};
  delete params.page;
  delete params.limit;
  delete params.sort;
  delete params['sort-by'];
  try {
    const {data, status} = await axiosClient.get(`website/departments/for-auctions`, {
      params
    })

    if (status === 200) {
      commit(`setDepartments`, data.departments);
      commit('setDepartmentsLoading', false);

      const selectedDept = getters.selectedDepartment;
      if (selectedDept) {
        commit('setCategories', selectedDept.categories);
        commit('setDynamicFields', selectedDept.dynamic_fields_template);
      }
    }
  } catch (e) {
    commit(`setDepartments`, []);
    commit('setDepartmentsLoading', false);
    logger.error(`Error while getting departments`, e)
  }
}

export async function getDepartmentsForHome({commit}) {
  commit('setHomeDepartmentsLoading', true);

  try {
    const {data, status} = await axiosClient.get(`website/departments`)

    if (status === 200) {
      commit(`setHomeDepartments`, data.departments);
      commit('setHomeDepartmentsLoading', false);
    }
  } catch (e) {
    commit(`setHomeDepartments`, []);
    commit('setHomeDepartmentsLoading', false);
    logger.error(`Error while getting departments`, e)
  }
}

export async function getDepartmentsForSellAnItem({state, commit}, {force = false}) {
  if (state.departmentsForSellAnItem && !force) {
    return;
  }
  const {data, status} = await axiosClient.get('website/departments/all-with-categories/');

  if (status === 200) {
    commit('setDepartmentsForSellAnItem', data);
  } else {
    logger.error("Departments were not fetched ", data);
  }
}

export async function getGenericData(_, url) {
  const {data, status} = await axiosClient.get(url);
  if (status === 200) {
    return data;
  } else {
    logger.error(`Data was not fetched`, data);
  }
}

export async function toggleWatchlist({state, commit}, {entity_name, uuid, is_favored}) {
  let entity = null;
  let entityObject = null;

  let entity_meta = {};

  switch (entity_name) {
    case 'auction':
      entity = state.auctions.results.filter(e => e.uuid === uuid)[0];
      entity_meta.url = 'website/auctions/favorite/';
      entity_meta.payload = {
        auction_uuid: uuid
      };
      entity_meta.success_message = entity.is_favored ? i18n.t("Auction has been removed from your watch list") : i18n.t("Auction has been added into your watch list");
      break;
    case 'lot':
      entity = state.lots.results.filter(e => e.uuid === uuid)[0];
      entityObject = state.lotObject.data;
      entity_meta.url = 'website/lots/favorite/';
      entity_meta.payload = {
        lot_uuid: uuid
      };
      entity_meta.success_message = is_favored ? i18n.t("Lot has been removed from your watch list") : i18n.t("Lot has been added into your watch list");
      break;
    default:
      console.error('Invalid OTAR Entity!');
      return;
  }

  const request = !is_favored
    ? axiosClient.post(entity_meta.url, entity_meta.payload)
    : axiosClient.delete(entity_meta.url, {data: entity_meta.payload});

  return request.then(() => {
    commit('showNotification', {
      title: i18n.t('Notification'),
      text: i18n.t(entity_meta.success_message),
      variant: 'success'
    });
    if (entity)
      entity.is_favored = !entity.is_favored;

    if (entityObject)
      entityObject.is_favored = !entityObject.is_favored;

  });
}

export async function toggleLotStartSubscription({state, commit}, {uuid}) {
  let lot = state.lots.results.filter(e => e.uuid === uuid)[0];

  let url = 'website/subscribe'
  let payload = {lot_uuid: uuid};

  const request = axiosClient.post(url, payload)

  return request.then(({data}) => {
    let message = data.message;
    let subscriptions = [...lot.subscriptions];
    if (subscriptions.includes('start')) {
      subscriptions = subscriptions.filter(s => s !== 'start')
    } else {
      subscriptions.push('start')
    }
    commit('showNotification', {
      title: i18n.t('Notification'),
      text: i18n.t(message),
      variant: 'success'
    });
    commit('mutateLotObject', {data: {uuid, subscriptions}});
    return data;
  });
}

export async function jumpIntoLot(_, params) {
  try {
    const {data, status} = await axiosClient.get(`website/misc`, {
      params: {
        action: 'jump_to_lot',
        ...params
      }
    });
    if (status === 200) {
      return data.lot_uuid;
    }
  } catch (e) {
    if (e.response.status !== 404) {
      logger.error(`Lot jump in data couldn't be fetched`);
    }
    return null;
  }
}

export function getLotDataSocket({commit, dispatch}, lot_uuids) {
  if (!lot_uuids.length) {
    return;
  }
  dispatch('socketLoginCallback')
    .then(() => {
      dispatch('sendToSocket', {
        'action': 'get_lot_data',
        'data': {
          lot_uuids,
        }
      });
    });
}

export function subscribeToLots({commit, dispatch, state}, lot_uuids) {
  if (!lot_uuids.length) {
    return;
  }
  dispatch('socketLoginCallback')
    .then(() => {
      // const lotsToSubscribe = [];
      // for (var index in lot_uuids) {
      //   // if (!state.socketSubscribedLots[lot_uuids[index]]) {
      //     lotsToSubscribe.push(lot_uuids[index]);
      //   // }
      // }
      if (lot_uuids.length) {
        // commit('mutateSocketSubscribedLots', lot_uuids);
        dispatch('sendToSocket', {
          'action': 'subscribe',
          'data': {
            'lot_uuids': lot_uuids,
          }
        });
      }
    });
}

export function socket__notification({state, commit}, context) {
  commit('fireSignal');

  let currency = null;
  if (state.lotObject.data?.currency?.code) {
    currency = state.lotObject.data.currency.code
  } else {
    const lot_uuid = context.data?.params?.lot_uuid
    if (lot_uuid) {
      const currentLot = state.lots.results.find(lot => lot.uuid === lot_uuid)
      if (currentLot) {
        currency = currentLot.currency.code
      }
    }
  }
  let message = context.data.message
  switch (context.data.code) {
    case 'ERROR_CODE_INVALID_BID_ONLY_INCREMENT':
      message = i18n.t('Invalid bid! Please strictly follow bid steps or set max bid value. ')
      break;
    case 'ERROR_CODE_INVALID_BID_ABOVE_INCREMENT':
      message = i18n.t('Invalid bid! Next bid must not be less than {amount}.', {amount: asCurrency(context.data.params.amount, currency)})
      break;
    case 'ERROR_CODE_INVALID_BID_ABOVE_CURRENT':
      message = i18n.t('Invalid bid! Next bid must be above current bid.')
      break;
    case 'ERROR_CODE_INVALID_BID_EXACT_UPCOMING_AMOUNT':
      message = i18n.t('Invalid bid! Bid must be valid amount respecting auction bidding increments.')
      break;
    default:

  }

  commit('showModalMsgBox', {
    title: i18n.t('Invalid Bid!'),
    content: i18n.t(message)
  });
}

export function socket__auction_updated({state, commit}, {data}) {
  commit('mutateAuction', {
    data
  });
}

export function socket__lot_updated({state, commit}, {data}) {
  commit('setIsOnline', true);
  commit('fireSignal')
  const fetch = data.fetch;
  delete data.fetch;

  if (data.auction) {
    commit('mutateAuction', {
      data: data.auction
    })
    delete data.auction
  }
  commit('mutateLotObject', {
    data,
    fetch
  });

  // if (data.winning_bid && data.winning_bid.customer_uuid === state.customer.uuid) {
  //   commit('setHasMyBid')
  // }

  // If the updated lot is the lot which is opened at the moment we must add latest bid in last_bids array
  if (state.lotObject.data.uuid === data.uuid) {
    if (!fetch && !data.last_bids) {
      commit('addIntoLastBids', data.winning_bid);
    }
  }
  commit('setLotObjectLoading', false);
}

export function socket__lot_finished({state, commit}, {data}) {
  commit('setIsOnline', true);
  commit('fireSignal')
  const fetch = data.fetch;
  delete data.fetch;
  commit('mutateLotObject', {
    data,
    fetch
  });
}

export function socket__bidder_updated({state, commit}, context) {
  // TODO This needs to be revisited
  // return;
  /*if (state.customer.uuid !== context.data.customer_uuid)
    return;*/

  commit('mutateBidderStatus', context.data);

 /* let toastParams = {
    title: i18n.t('Notification'),
    autoHideDelay: 250000,
  };
  switch (context.data.current_bidder_status) {
    case "approved":
      toastParams.text = i18n.t("You have been approved as a bidder, congratulations.");
      toastParams.variant = "success";
      break;
    case "rejected":
      toastParams.text = i18n.t("You have been rejected as a bidder.");
      toastParams.variant = "danger";
      break;
    case "pending":
      toastParams.text = i18n.t("You are being considered as a bidder, please stand by.");
      toastParams.variant = "warning";
      break;
  }

  commit('showNotification', toastParams);*/
}

export async function getCustomerMeta({commit}) {
  const {data, status} = await axiosClient.get('website/auth/me');
  if (status === 200) {
    commit('mutateCustomerMeta', data);
  }
}

export async function registerAsBidder({state, dispatch, commit}, {auction, force = false}) {
  if (auction.is_bidder) {
    return {'success': true};
  }

  let eventTime =
    auction.after_sale_end_date
      ? moment(auction.after_sale_end_date).toDate().getTime()
      : 0;
  if (auction.status === 'completed' && eventTime < getCurrentServerTime()) {
    return {'success': false}
  }
  return dispatch('socketLoginCallback')
    .then(() => {
      if (!state.is_authorized) {
        return {'success': false};
      }

      return axiosClient.post('website/bidders/create/', {
        "auction_uuid": auction.uuid,
        force
      }).then((res) => {
        commit('mutateAuction', {
          data: {
            uuid: auction.uuid,
            is_bidder: true,
            is_approved_bidder: res.data.status === 'approved',
            bidding_url: res.data.bidding_url,
            bidding_url_expire_date: res.data.bidding_url_expire_date
          }
        });
        // state.auctionObject.data.is_bidder = true;
        // state.auctionObject.data.bidding_url = res.data.bidding_url;
        // state.auctionObject.data.bidding_url_expire_date = res.data.bidding_url_expire_date;
        return {'success': true, status: res.data.status};
      }).catch((err) => {
        console.error('Failed to register as bidder');
        return {
          'success': false,
          error: err.response.data.error,
          error_code: err.response.data.error_code,
          params: err.response.data.params,
          payment_url: err.response.data.payment_url
        }
      });
    })
}

export async function generateBiddingUrl({state, dispatch}, {auction_uuid}) {
  return dispatch('socketLoginCallback')
    .then(() => {
      if (!state.is_authorized) {
        return {};
      }

      return axiosClient.post('website/bidders/generate-bidding-url/', {
        "auction_uuid": auction_uuid,
      }).then((res) => {
        state.auctionObject.data.is_bidder = true;
        state.auctionObject.data.bidding_url = res.data.bidding_url;
        state.auctionObject.data.bidding_url_expire_date = res.data.bidding_url_expire_date;
        return {biddingUrl: res.data.bidding_url};
      }).catch((err) => {
        logger.error({
          error: err.response.data.error,
          error_code: err.response.data.error_code,
          params: err.response.data.params
        })
        return {
          error: err.response.data.error,
          error_code: err.response.data.error_code,
          params: err.response.data.params
        }
      });
    })
}

export async function createMaxBid({commit, state}, payload) {
  let response = null;
  try {
    response = await axiosClient.post('website/max-bids/', payload);
  } catch(err) {
    let message = '';
    if (err && err.response && err.response.status === 422) {
      if (err.response.data.message) {
        message = i18n.t(err.response.data.message);
        if (!payload.dontShowErrorDialog) {
          commit('showModalMsgBox', {
            title: i18n.t('Max Allowed limit reached!'),
            content: message,
            variant: 'danger'
          });
        }
      } else {
        if (err.response.data.errors && err.response.data.errors.lot_uuid && err.response.data.errors.lot_uuid.length) {
          message = i18n.t(err.response.data.errors.lot_uuid[0]);
        } else if (err.response.data.errors && err.response.data.errors.amount && err.response.data.errors.amount.length) {
          message = i18n.t(err.response.data.errors.amount[0]);
        }
        if (!payload.dontShowErrorDialog) {
          commit('showModalMsgBox', {
            title: i18n.t('Max Bid Not Allowed!'),
            content: message,
            variant: 'danger'
          });
        }
      }
    }
    return {success: false, message};
  }
  if (response.status === 200) {
    commit('mutateLotMaxBid', {
      lot_uuid: payload.lot_uuid || payload.lot?.uuid,
      ...response.data.max_bid
    });
    // commit('setHasMyBid')

    let lot = state.lotObject.data;
    if (!lot.uuid) {
      lot = state.lots.results.find(l => l.uuid === payload.lot_uuid)
    }
    commit('showNotification', {
      title: i18n.t('Max Bid Created!'),
      text: i18n.t("You have set max bid with amount of {amount}", {
        amount: '<strong>' + asCurrency(
          payload.amount,
          lot.currency.code
        ) + '</strong>',
      }),
      variant: 'success'
    });
  }
  response.success = true
  return response;
}

export async function createCommissionBid({commit, state}, payload) {
  let response = null;
  try {
    response = await axiosClient.post('website/max-bids/', payload);
  } catch (err) {
    let message = '';
    if (err && err.response && err.response.status === 422) {
      if (err.response.data.message) {
        message = i18n.t(err.response.data.message);
      } else {
        if (err.response.data.errors && err.response.data.errors.lot_uuid && err.response.data.errors.lot_uuid.length) {
          message = i18n.t(err.response.data.errors.lot_uuid[0]);
        } else if (err.response.data.errors && err.response.data.errors.amount && err.response.data.errors.amount.length) {
          message = i18n.t(err.response.data.errors.amount[0]);
        }

        try {
          message = JSON.parse(message)
          message = i18n.t(message.message, message.params)
        } catch (e) {
          logger.error(e)
        }
      }

      commit('showModalMsgBox', {
        title: i18n.t('Commission Bid Not Allowed!'),
        content: message,
        variant: 'danger'
      });
    }
    return {success: false, message};
  }

  if (response.status === 200) {
    commit('mutateLotMaxBid', {
      lot_uuid: payload.lot_uuid || payload.lot?.uuid,
      ...response.data.max_bid
    });

    commit('setHasMyBid', payload.lot_uuid)

    commit('showNotification', {
      title: i18n.t('Commission Bid Created!'),
      text: i18n.t("You have created commission bid with amount of {amount}", {
        amount: '<strong>' + asCurrency(
          payload.amount,
          state.lotObject.data.currency.code
        ) + '</strong>',
      }),
      variant: 'success'
    });
  }

  response.success = true;
  return response;
}

export async function createTelephoneBid({commit, state}, payload) {
  let response = null;
  try {
    response = await axiosClient.post('website/max-bids/', payload)
  } catch (err) {
    let message = '';
    if (err && err.response && err.response.status === 422) {
      if (err.response.data.message) {
        message = i18n.t(err.response.data.message)
        commit('showModalMsgBox', {
          title: i18n.t('Problem Placing a Telephone Bid'),
          content: message,
          variant: 'danger'
        });
      } else {
        message = i18n.t('There Was a Problem in Telephone Bidder Registration. Please Try Again.');
        if (Object.keys(err.response.data.errors).length) {
          message = Object.values(err.response.data.errors)[0][0];
        }

        try {
          message = JSON.parse(message)
          message = i18n.t(message.message, message.params)
        } catch (e) {
          logger.error(e)
        }

        commit('showModalMsgBox', {
          title: i18n.t('Error in Telephone Bidder Registration'),
          content: message,
          variant: 'danger'
        });
      }
    }
    return {success: false, message};
  }
  if (response.status === 200) {
    commit('mutateLotMaxBid', {
      lot_uuid: payload.lot_uuid || payload.lot?.uuid,
      ...response.data.max_bid
    });

    // commit('setHasMyBid', payload.lot_uuid)

    if (response.data.update) {
      commit('showNotification', {
        title: i18n.t('Success'),
        text: i18n.t("You have successfully updated your telephone bidder registration."),
        variant: 'success'
      });
    } else {
      commit('showNotification', {
        title: i18n.t('Success'),
        text: i18n.t("You have been successfully registered as telephone bidder!"),
        variant: 'success'
      });
    }
  }
  response.success = true
  return response;
}

export async function deleteMaxBid({commit, state}, {uuid}) {
  const {status} = await axiosClient.delete('website/max-bids/', {data: {lot_uuid: uuid}});
  if (status === 200) {
    commit('deleteLotMaxBid');

    commit('showNotification', {
      title: i18n.t('Max Bid Deleted!'),
      text: i18n.t("Your max bid was successfully deleted"),
      variant: 'success'
    });
  }
  return status;
}

export async function deleteCommissionBid({commit, state}, {uuid}) {
  const {status} = await axiosClient.delete('website/max-bids/', {data: {lot_uuid: uuid}});
  if (status === 200) {
    commit('deleteLotMaxBid');

    commit('showNotification', {
      title: i18n.t('Commission Bid Deleted!'),
      text: i18n.t("Your commission bid was successfully deleted"),
      variant: 'success'
    });
  }
  return status;
}

export async function deleteTelephoneBid({commit, state}, {uuid}) {
  const {status} = await axiosClient.delete('website/max-bids/', {data: {lot_uuid: uuid}});
  if (status === 200) {
    commit('deleteLotMaxBid');

    commit('showNotification', {
      title: i18n.t('Telephone Bid Deleted!'),
      text: i18n.t("Your telephone bid was successfully deleted"),
      variant: 'success'
    });
  }
  return status;
}

export function loginHandler({state}, {event = null} = {}) {
  if (typeof state.mainConfig.loginHandler === 'function') {
    state.mainConfig.loginHandler(event);
  }
}

export function signupHandler({state}, {event = null} = {}) {
  if (typeof state.mainConfig.signupHandler === 'function') {
    state.mainConfig.signupHandler(event);
  }
}

export function linkHandler({state}, {event, link}) {
  if (typeof link.handler === 'function') {
    link.handler(event)
  }
}

export async function show401Notification({state, commit}) {
  commit('showModalMsgBox', {
    title: i18n.t('Unauthorized!'),
    content: i18n.t("You must be authorized to perform this action")
  });
}

export async function sendBidInSocket({state, commit, dispatch}, payload) {
  if (state.is_authorized) {
    if (state.socket.loginFinished) {
      dispatch('sendToSocket', payload);
    } else {
      dispatch('socketLoginCallback')
        .then(() => {
          console.log("socketLoginCallback VASHA");
          dispatch('sendToSocket', payload);
        })
    }
  } else {
    dispatch('show401Notification')
  }
}

export async function socketOpen({state}) {
  return new Promise((resolve, reject) => {
    checkSocket();
    let timeout;

    function checkSocket() {
      if (state.socket.isOpened) {
        clearTimeout(timeout)
        resolve();
      } else {
        timeout = setTimeout(() => {
          checkSocket()
        }, 200)
      }
    }
  })
}

export async function sendSocketLogin({state, dispatch}) {
  await dispatch('socketOpen');
  const token = Vue.cookie.get(CONFIG.artisioCookieName);
  let socketLotPayloadExtraFields = [
    'last_bids'
  ];
  dispatch('customerHasAcceptedTermsAndConditions')
  // if (!token) {
  //   state.socket.loginFinished = true;
  //   return;
  // }
  dispatch('sendToSocket', {
    'action': 'login',
    'data': {
      token,
      artisio_client_id: CONFIG.clientId,
      socket_lot_payload_extra_fields: socketLotPayloadExtraFields.filter(field => field !== '')
    },
  });
}

export async function sendSocketLogout({dispatch}) {
  dispatch('sendToSocket', {
    'action': 'logout',
    'data': {},
  });
}

export async function socket__login_callback({state, commit}, {data}) {
  if (data.customer) {
    commit('setCurrentCustomer', data.customer)
  }
  state.socket.loginFinished = true;
}

export async function socket__logout_callback(_) {

}

export async function socket__server_time({commit}, {data}) {
  commit('updateSocketLastInteractionTime')
  commit('setServerTime', data.time)
}

export async function socketLoginCallback({state}) {
  return new Promise((resolve, reject) => {
    checkSocket();
    let timeout;

    function checkSocket() {
      if (state.socket.loginFinished) {
        clearTimeout(timeout)
        resolve(state.customer);
      } else {
        timeout = setTimeout(() => {
          checkSocket()
        }, 200)
      }
    }
  })
}

export async function getSettings({commit}) {
  return new Promise((resolve, reject) => {
    axiosClient.get('website/settings/').then((responseData) => {
      resolve(responseData.data);
      commit('setSettings', responseData.data);
    }).catch((err) => {
      reject(err.response);
    });
  })
}

export async function login({commit, dispatch}, payload) {
  return new Promise((resolve, reject) => {
    axiosClient.post('website/auth/login/', payload).then((responseData) => {
      // commit('clearSocketSubscribedLots');
      resolve(responseData.data);
      afterLogin();

      responseData.data.remember = payload.remember
      commit('setCurrentUser', responseData.data);
      dispatch('sendSocketLogin');
    }).catch((err) => {
      reject(err.response);
    });
  })
}

export async function resendVerificationEmail({commit, dispatch}, payload) {
  return new Promise((resolve, reject) => {
    axiosClient.post('website/auth/resend-verification/', payload).then((responseData) => {
      resolve(responseData.data);
    }).catch((err) => {
      reject(err.response);
    });
  })
}

export async function resendVerificationPassword({commit, dispatch}, payload) {
  return new Promise((resolve, reject) => {
    axiosClient.post('website/auth/resend-password-reset/', payload).then((responseData) => {
      resolve(responseData.data);
    }).catch((err) => {
      reject(err.response);
    });
  })
}

export async function signup({commit}, {payload}) {
  return new Promise((resolve, reject) => {
    payload.addresses = [
      {
        'type': 'billing',
        'is_primary': true,
        ...payload.billing_address
      },
      {
        'type': 'shipping',
        'is_primary': true,
        ...payload.shipping_address
      }
    ];
    delete payload.billing_address;
    delete payload.shipping_address;

    if (Object.values(payload.bank_account).every(value => value === "")) {
      payload.bank_account = null;
    }
    axiosClient.post('website/auth/register/', payload).then((responseData) => {
      resolve(responseData.data.message);
    }).catch((err) => {
      reject(err.response.data.errors);
    });
  })
}

export async function profileUpdate({commit}, {payload}) {
  return new Promise((resolve, reject) => {
    payload.addresses = [
      {
        'type': 'billing',
        'is_primary': true,
        ...payload.billing_address
      },
      {
        'type': 'shipping',
        'is_primary': true,
        ...payload.shipping_address
      }
    ];
    delete payload.billing_address;
    delete payload.shipping_address;

    if (Object.values(payload.bank_account).every(value => value === "")) {
      payload.bank_account = null;
    }
    axiosClient.patch('website/auth/profile/', payload).then((responseData) => {
      resolve(responseData.data.message);
      commit('setCurrentCustomer', responseData.data)
    }).catch((err) => {
      reject(err.response.data.errors);
    });
  })
}

export async function validatePasswordResetToken({commit}, {token}) {
  //TODO
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve("The link is invalid or expired")
    }, 1000)
  })
}

export async function resetPassword({commit}, payload) {
  return new Promise((resolve, reject) => {
    axiosClient.post('website/auth/password-reset/', payload).then((responseData) => {
      resolve(responseData.data.message);
    }).catch((err) => {
      reject(err.response.data.message);
    });
  })
}

export async function requestPasswordReset(_, email) {
  return new Promise((resolve, reject) => {
    axiosClient.post('website/auth/request-password-reset/', {'email': email}).then((response) => {
      resolve(response.data);
    }).catch((err) => {
      reject(err.response.data.message);
    })
  })
}

export async function verifyEmailAddress(_, token) {
  return new Promise((resolve, reject) => {
    axiosClient.post('website/auth/email-verification/', {'token': token}).then((response) => {
      resolve(response.data.message);
    }).catch((err) => {
      reject(err.response.data['Error']);
    })
  });
}

export async function saveLotFilters({commit}, filters) {
  commit('setLotFilters', filters);
}

export async function addToFavourites({dispatch, state}) {
  if (!state.is_authorized) {
    dispatch('show401Notification');
    return;
  }
  const lot = state.lotObject.data;
  return dispatch('toggleWatchlist', {
    entity_name: 'lot',
    uuid: lot.uuid,
    is_favored: lot.is_favored,
  })
}

export async function subscribeToLotStart({dispatch, state}) {
  if (!state.is_authorized) {
    dispatch('show401Notification');
    return;
  }
  const lot = state.lotObject.data;
  dispatch('toggleLotStartSubscription', {
    uuid: lot.uuid
  })
}

export async function submitMakeAnOffer({commit}, {lot_uuid, amount}) {
  return await axiosClient.post('website/user-offer-commission', {lot_uuid, amount});
}

export async function getInvoices({commit}, {pagination, filters = null}) {
  commit('setInvoicesLoading', true)
  commit('mutateInvoicePagination', pagination);
  commit('mutateInvoiceFilters', filters);

  const {data, status} = await axiosClient.get('website/invoices/', {
    params: {...pagination, ...filters}
  });
  commit('setInvoicesLoading', false);

  if (status === 200) {
    commit('setInvoices', data);
  } else {
    logger.error("Invoices were not fetched ", data);
  }
}

export async function getInvoicePdf({commit}, {uuid}) {
  const {data, status} = await axiosClient.get(`website/invoices/${uuid}/pdf`);
  if (status === 200) {
    return data.base64;
  }
  logger.error(`Invoice pdf was not fetched for ${uuid}`, data);
  return false;
}

export async function requestPaymentUrl({commit}, {invoiceUuid, providerName}) {
  return new Promise((resolve, reject) => {
    axiosClient.get(`website/invoices/${invoiceUuid}/${providerName}/payment-link`).then(({data}) => {
      resolve(data.url);
    }).catch(() => {
      logger.error(`Invoice payment url was not fetched for invoice: ${invoiceUuid} and provider: ${providerName}`);
      reject();
    });
  });
}

export async function getSettlements({commit}, {pagination, filters = null}) {
  commit('setSettlementsLoading', true)
  commit('mutateSettlementPagination', pagination);
  commit('mutateSettlementFilters', filters);

  const {data, status} = await axiosClient.get('website/settlements/', {
    params: {...pagination, ...filters}
  });
  commit('setSettlementsLoading', false);

  if (status === 200) {
    commit('setSettlements', data);
  } else {
    logger.error("Settlements were not fetched ", data);
  }
}

export async function getSettlementPdf({commit}, {uuid}) {
  const {data, status} = await axiosClient.get(`website/settlements/${uuid}/pdf`);
  if (status === 200) {
    return data.base64;
  }
  logger.error(`Settlement pdf was not fetched for ${uuid}`, data);
  return false;
}


export async function getItems({commit}, {pagination, filters = null, purchasedItemsMode = false}) {
  commit('setItemsLoading', true)
  commit('mutateItemPagination', pagination);
  commit('mutateItemFilters', filters);

  const {
    data,
    status
  } = await axiosClient.get(purchasedItemsMode ? 'website/items/purchased-items' : 'website/items/', {
    params: {...pagination, ...filters}
  });
  commit('setItemsLoading', false);

  if (status === 200) {
    commit('setItems', data);
  } else {
    logger.error("Items were not fetched ", data);
  }
}


export async function getTemporaryItems({commit, state}) {
  commit('setTemporaryItemsLoading', true)
  const pagination = state.temporaryItems.pagination;
  const filters = state.temporaryItems.filters;

  const {data, status} = await axiosClient.get('website/items/temporary/', {
    params: {...pagination, ...filters, without_inventory: true}
  });
  commit('setTemporaryItemsLoading', false);

  if (status === 200) {
    commit('setTemporaryItems', data);
  } else {
    logger.error("Temporary Items were not fetched ", data);
  }
}


export async function saveTemporaryItem({dispatch, commit, state}, payload) {
  try {
    const response = await axiosClient.post('website/items/temporary', payload);
    // if (response.status === 201) {
    //   dispatch('getTemporaryItems')
    // }
    return response
  } catch (e) {
    logger.error(e.response);
    return e.response;
  }
}


export async function getCountries({commit}) {
  try {
    const {data, status} = await axiosClient.get('website/countries/');

    if (status === 200) {
      commit('setCountries', data);
    }

  } catch (e) {
    logger.error(`Error while getting countries`, e)
  }
}

export async function getTitles({commit}) {
  try {
    const {data, status} = await axiosClient.get('website/titles/');

    if (status === 200) {
      commit('setTitles', data);
    }

  } catch (e) {
    logger.error(`Error while getting countries`, e)
  }
}

export async function validateUpcomingAmount({state, commit}, params) {
  if (params['currentAmount'] && new PreciseNum(params['newAmount']).lte(new PreciseNum(params['currentAmount']))) {
    // Let socket handle the error.
    return true;
  }

  const increments = params.increments.sort((a, b) => {
    const up_to_A = parseFloat(a.up_to ?? Number.MAX_SAFE_INTEGER);
    const up_to_B = parseFloat(b.up_to ?? Number.MAX_SAFE_INTEGER);

    if (up_to_A > up_to_B)
      return 1;

    if (up_to_A < up_to_B)
      return -1;

    return 0;
  });
  const currentAmount = new PreciseNum(params['currentAmount'] || params['startPrice'] || 0);
  const finalAmount = new PreciseNum(params['newAmount']);

  let lowerAmount = new PreciseNum(0);

  for (const increment of increments) {
    const innerIncrement = increment['increment'];
    const incrementRange = increment['increment_range'];
    const upTo = new PreciseNum(increment['up_to'] ?? Number.MAX_SAFE_INTEGER);
    let incrementRangeIdx = 0;

    while (currentAmount.lt(upTo)) {
      if (currentAmount.eq(finalAmount)) {
        return true;
      } else if (currentAmount.gt(finalAmount)) {
        let low = null;

        if (params['currentAmount']) {
          low = lowerAmount.gt(new PreciseNum(params['currentAmount'])) ? lowerAmount.toString() : null;
        } else {
          low = params['startPrice'] && lowerAmount.gte(new PreciseNum(params['startPrice'])) ? lowerAmount.toString() : null;
        }

        commit('showUpcomingAmountPopover', {
          low: low,
          high: currentAmount.toString(),
          stateObjectName: params['stateObjectName']
        });
        return false;
      }

      if (innerIncrement) {
        lowerAmount.setNum(currentAmount);
        currentAmount.plus(new PreciseNum(innerIncrement));
      } else {
        lowerAmount.setNum(currentAmount);
        currentAmount.plus(new PreciseNum(incrementRange[incrementRangeIdx]));
        incrementRangeIdx++;
      }
    }
  }
  return false;
}

export function logout({dispatch, commit}) {
  axiosClient.post('/website/auth/logout/')
    .then(({data}) => {
      dispatch('sendSocketLogout')
      commit('logoutUser')
      dispatch('sendSocketLogin')
    })
}

export async function sendBuyNowAfterSale({commit, state}, lot_uuid) {
  try {
    const response = await axiosClient.post('website/lots/buy-now', {'uuid': lot_uuid});
    if (response.status === 422) {
      commit('showNotification', {
        title: i18n.t('Max Allowed limit reached!'),
        text: i18n.t("You have reached your bidding limit. Please contact us to increase it."),
        variant: 'danger'
      });
    } else if (response.status === 200) {
      console.log(response);
      commit('setLotObjectData', response.data)
      commit('showNotification', {
        title: i18n.t('Purchase successful!'),
        text: i18n.t("You have bought the lot!"),
        variant: 'success'
      });
    }
  } catch (e) {
    commit('showNotification', {
      title: i18n.t('Server Error.'),
      text: i18n.t(e.response && e.response.data && e.response.data.message ? e.response.data.message : 'Server Error.'),
      variant: 'danger'
    });
  }
}

export function socketKeepAlive({dispatch}) {
  dispatch('sendToSocket', {
    'action': 'keep-alive',
    'data': {}
  });
}

export function socketGetServerTime({dispatch}) {
  dispatch('sendToSocket', {
    'action': 'get_server_time',
    'data': {}
  });
}

export function sendToSocket({commit}, payload) {
  commit('updateSocketLastInteractionTime');
  Vue.prototype.$socket.sendObj(payload);
}

export function customerHasAcceptedTermsAndConditions({state, commit}) {
  // We just make request and the response is handled inside axios interceptor
  axiosClient.post('/website/customer-terms-and-conditions/', {version: state.mainConfig.termsAndConditionsVersion})
}
