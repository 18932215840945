import TEMPLATES from './templates'

const DEFAULT_CONFIG = {
  /**
   * Your unique Client ID
   *
   * @type {string}
   */
  clientId: '',

  /**
   * The language in which the UI should be displayed
   *
   * @type {string}
   */
  lang: "en",

  /**
   * The default page that should be displayed when opening online auctions page
   * and bidding app starts working.
   *
   * Available options:
   *  - /home
   *  - /auctions
   *  - /lots
   *  - /private-treaty/auctions
   *  - /private-treaty/lots
   *  - /favorite-auctions
   *  - /favorite-lots
   *  - /invoices
   *  - /settlements
   *  - /items
   *  - /temporary-items
   *  - /profile
   *
   * @type {string}
   */
  defaultPage: "/home",

  /**
   * The URL on which user will be redirected when clicking on login buttons/links
   *
   * @type {string}
   */
  loginUrl: '',

  /**
   * Javascript function which will be executed when login button is clicked.
   * You must provide either `loginUrl` or `loginHandler`.
   * If you provide both, both will work.
   *
   * @type {function}
   */
  loginHandler: null,

  /**
   * The URL on which user will be redirected when clicking on signup buttons/links
   *
   * @type {string}
   */
  signupUrl: '',

  /**
   * Javascript function which will be executed when signup button is clicked.
   * You must provide either `signupUrl` or `signupHandler`
   * If you provide both, both will work.
   *
   * @type {function}
   */
  signupHandler: null,

  /**
   * Links which will be displayed bellow bidding section.
   * Each link in this array must be object containing the following properties.
   *
   *  - `text`     - Required - The actual text of the link.
   *  - `url`      - Optional - URL which should be opened
   *  - `target`   - Optional - html `'target'` attribute: `'_self'`, `"_blank"`, etc...
   *  - `handler`  - Optional - JavaScript function which will be executed when link is clicked.
   *
   * Typical examples:
   * ```javascript
   * [
   *   {
   *     url: "/shipping-details",
   *     text: "Shipping Details"
   *   },
   *   {
   *     text: "Terms and Conditions",
   *     handler: function(){
   *       // TODO Show modal with Terms and conditions
   *     }
   *   }
   * ]
   * ```
   *
   * @type {Array}
   */
  links: [],

  /**
   * The URL which must return translations on all languages.
   * If you plan to have only English language you can skip this or set it to an empty string.
   * Response Example:
   * ```javascript
   * {
   *   "en": {
   *     "Buy now": "Buy now",
   *     "Place a Bid": "Place a Bid"
   *   },
   *   "de": {
   *     "Buy now": "Kaufe jetzt",
   *     "Place a Bid": "Ein Gebot abgeben"
   *   }
   * }
   * ```
   *
   * @type {string}
   */
  translationsUrl: '',

  /**
   * Translations object
   *
   * Example:
   * ```javascript
   * {
   *   "en": {
   *     "Buy now": "Buy now",
   *     "Place a Bid": "Place a Bid"
   *   },
   *   "de": {
   *     "Buy now": "Kaufe jetzt",
   *     "Place a Bid": "Ein Gebot abgeben"
   *   }
   * }
   * ```
   *
   * @type {Object}
   */
  translations: {},

  /**
   * NOT FULLY IMPLEMENTED
   * ---------------------
   * Which social share icons to be displayed.
   * This can be either boolean or array
   *  - `true`   - All supported social sharing buttons will be displayed
   *  - `false`  - None of the social sharing buttons will be displayed
   *  - `Array`          - Only those buttons will be displayed
   *
   * Available social sharing buttons: `'email'`, `'facebook'`, `'twitter'`, `'linkedin'`
   *
   * Example: `socialShares: `['facebook', 'email', 'twitter']`
   *
   * @type {boolean|Array}
   */
  socialShares: false,

  /**
   * To show print button or not.
   * This can be either boolean or function
   *  - `true`      - The button will be displayed and default printing behavior will happen
   *  - `false`     - The button will be hidden
   *  - `Function`  - The button will be displayed and specified function will
   *                    be called
   *
   * @type {boolean|function}
   */
  printButton: false,

  /**
   * Whether to show "Jump to lot" input or not
   *
   * @type {boolean}
   */
  jumpToLot: true,

  /**
   * Whether to show breadcrumbs or not.
   *
   * @type {boolean}
   */
  showBreadcrumbs: true,

  /**
   * Whether to show "Previous", "Next" and "Back to list" buttons or not
   *
   * @type {boolean}
   */
  showPrevNextList: true,

  /**
   * To show the latest 5 bids in lot view or not.
   *
   * @type {boolean}
   */
  showLatestBids: false,

  /**
   * Whether to enable "Add to watchlist" button or not
   *
   * @type {boolean}
   */
  enableWatchlist: true,

  /**
   * Whether to show select box for page size or not
   *
   * @type {boolean}
   */
  perPageSelect: true,

  /**
   * Whether to show authentication buttons or not
   *
   * @type {boolean}
   */
  enableAuth: true,

  /**
   * Whether to enable bidding or not
   *
   * @type {boolean}
   */
  enableBidding: true,

  /**
   * Whether to enable sidebar filters or not
   *
   * @type {boolean}
   */
  enableFilters: true,

  /**
   * Whether to enable sorting options dropdown
   *
   * @type {boolean}
   */
  enableSorting: true,

  /**
   * Whether to show countdown timer or not
   *
   * @type {boolean}
   */
  showCountdown: true,

  /**
   * Whether to show description of lot in sidebar or not.
   * If you set it to false it will be displayed bellow images
   *
   * @type {boolean}
   */
  descriptionInSidebar: false,

  /**
   * Whether to move share and print buttons in sidebar.
   * If you set it to false they will be displayed bellow images
   *
   * @type {boolean}
   */
  sharesInSidebar: false,

  /**
   * CSS Selector.
   * These elements will be visible when user is guest, but hidden when user is authorized
   *
   * @type {string}
   */
  visibleForGuestsSelector: '.ta-visible-for-guest',

  /**
   * CSS Selector.
   * These elements will be visible when user is authorized, but hidden when user is guest
   *
   * @type {string}
   */
  visibleForAuthSelector: '.ta-visible-for-auth',

  /**
   * Artisio SSO cookie name
   *
   * @type {string}
   */
  artisioCookieName: 'artisio_timed_sso',

  /**
   * Whether to enable private treaty buttons like: "Make an offer" and "Make an Enquiry"
   *
   * @type {boolean}
   */
  enablePrivateTreaty: false,

  /**
   * The email address which should be used for "Make an Enquiry" button for "mailto"
   *
   * @type {string}
   */
  makeAnEnquiryEmail: '',

  /**
   * Artisio branch uuid for which you want to display content on website.
   * If you leave this null it will display content for all branches
   *
   * @type {string}
   */
  branchUuid: '',

  /**
   * Whether to show collection information in lot listing an in lot inner page
   *
   * @type {boolean}
   */
  showCollectionInfo: true,

  /**
   * Dynamic fields which must be rendered for lot description
   * Giving false here means that item's actual description should be rendered
   * Giving true here means that all dynamic fields should be iterated and rendered
   * If it is an array, you should give Artisio dynamic field names which will be used to render fields
   *
   * @type {Array|boolean}
   */
  descriptionDynamicFields: false,

  /**
   * Display "Show More" button when rendering description
   *
   * @type {boolean}
   */
  showMoreInDescription: false,

  /**
   * Hide department list from filters sidebar if department is selected
   *
   * @type {boolean}
   */
  hideDepartmentsIfSelected: false,

  /**
   * Whether to always show categories or only show when department is selected
   *
   * @type {boolean}
   */
  alwaysShowCategories: false,

  /**
   * Default templates for the webapp. For more information check [Templates](./templates) section
   *
   * @type {Object}
   */
  templates: {
    ...TEMPLATES
  },

  /**
   * The type how the pagination should be handled
   * Options: `'PAGINATION'`, `'LOAD_MORE'`, `'SCROLL'`
   *
   * @type {string}
   */
  paginationType: 'PAGINATION',

  /**
   * The default sort field for lots
   * Any valid lot field is accepted.
   * Ex: `lot_no`, `end_date`, `start_date`, `visited`
   *
   * @type {string}
   */
  lotDefaultSortField: 'lot_no',

  /**
   * The default sort field for favorite lots
   * Any valid lot field is accepted.
   * Ex: `lot_no`, `end_date`, `start_date`, `visited`
   *
   * @type {string}
   */
  favoriteLotDefaultSortField: 'end_date',

  /**
   * The default sort by for lots
   * Valid Options: `'asc'`, `'desc'`
   *
   * @type {string}
   */
  lotDefaultSortBy: 'asc',

  /**
   * The default sort by for favorite lots
   * Valid Options: `'asc'`, `'desc'`
   *
   * @type {string}
   */
  favoriteLotDefaultSortBy: 'asc',

  /**
   * The default sort field for auctions
   * Any valid auction field is accepted.
   * Ex: `auction_no`, `end_date`, `start_date`
   *
   * @type {string}
   */
  auctionDefaultSortField: 'end_date',

  /**
   * The default sort field for favorite auctions
   * Any valid auction field is accepted.
   * Ex: `auction_no`, `end_date`, `start_date`
   *
   * @type {string}
   */
  favoriteAuctionDefaultSortField: 'end_date',

  /**
   * The default sort by for auctions
   * Valid Options: `'asc'`, `'desc'`
   *
   * @type {string}
   */
  auctionDefaultSortBy: 'asc',

  /**
   * The default sort by for favorite auctions
   * Valid Options: `'asc'`, `'desc'`
   *
   * @type {string}
   */
  favoriteAuctionDefaultSortBy: 'asc',

  /**
   * Default limit for lot pagination
   * Options can be configured by option `perPageOptions`
   * Maximum supported number is 100. Anything above 100 will be reset to 100
   *
   * @type {number}
   */
  lotPaginationLimit: 25,

  /**
   * Default limit for favorite lots pagination
   * Options can be configured by option `perPageOptions`
   * Maximum supported number is 100. Anything above 100 will be reset to 100
   *
   * @type {number}
   */
  favoriteLotPaginationLimit: 25,

  /**
   * Default limit for auction pagination
   * Options can be configured by option `perPageOptions`
   * Maximum supported number is 100. Anything above 100 will be reset to 100
   *
   * @type {number}
   */
  auctionPaginationLimit: 25,

  /**
   * Default limit for favorite auctions pagination
   * Options can be configured by option `perPageOptions`
   * Maximum supported number is 100. Anything above 100 will be reset to 100
   *
   * @type {number}
   */
  favoriteAuctionPaginationLimit: 25,

  /**
   * Terms and Conditions HTML string.
   * If this and `termsAndConditionsUrl` both are set to false terms and conditions links will be hidden
   *
   * @type {boolean}
   */
  termsAndConditionsHtml: false,

  /**
   * Terms and Conditions URL.
   * If this and `termsAndConditionsHtml` both are set to false terms and conditions links will be hidden.
   * If this and `termsAndConditionsHtml` both are set non-false value this will have higher priority
   *
   * @type {boolean}
   */
  termsAndConditionsUrl: false,

  /**
   * Terms and Conditions version.
   * This version will be used to detect if the current user has accepted latest version of terms and conditions
   * or not. If not, then user will receive a modal to accept terms and conditions before doing anything else
   * on the website.
   *
   * @type {string}
   */
  termsAndConditionsVersion: '',

  /**
   * Currency formatting special string.
   * {symbol} can be moved at the end of the formatting string or replaced with {code} to display currency code, instead of symbol
   * Whitespace can be inserted between {symbol} and {number:} to have visual separation on webapp.
   *
   * In {number:} "," acts as thousands separator. Following options are supported for a thousand separator: {
   *   Dot - "."
   *   Comma - ","
   *   Apostrophe - "‘"
   *   Space - " "
   * }
   *
   * In {number:} "." acts as decimal separator. Following options are supported for a decimal separator: {
   *   Dot - "."
   *   Comma - ","
   * }
   *
   * Example:
   *  - "{symbol}{number:000 000,00}" - $100 000,99
   *  - "{symbol}{number:000,000.00}" - $100,000.99
   *  - "{symbol}{number:000000,00}" - $100000,99
   *  - "{symbol}{number:000'000,00}" - $100'000,99
   *  - "{code} {number:000 000,00}" - USD 100 000,99
   *  - "{number:000 000,00} {code}" - 100 000,99 USD
   *  - "{number:000 000,00}{code}" - 100 000,99USD
   *  - "{number:000 000.00} {code}" - 100 000.99 USD
   *  - "{number:000 000.00}{code}" - 100 000.99USD
   *  - "€{number:000 000,00}" - €100 000,99
   *
   * @type {string}
   */
  currencyFormat: '{symbol}{number:000,000.00}',

  /**
   * Default format how dates should be displayed in Web App
   *
   * @type {string}
   */
  dateFormat: 'DD MMM YYYY',

  /**
   * Default format how datetime should be displayed in Web App
   *
   * @type {string}
   */
  datetimeFormat: 'DD MMM YYYY HH:mm',

  /**
   * Default format how datetime with seconds should be displayed
   *
   * @type {string}
   */
  datetimeFormatWithSeconds: 'DD MMM YYYY HH:mm:ss',

  /**
   * Default format how time should be displayed in Web App
   *
   * @type {string}
   */
  timeFormat: 'HH:mm',

  /**
   * Default format how time with seconds should be displayed in Web App
   *
   * @type {string}
   */
  timeWithSecondsFormat: 'HH:mm:ss',

  /**
   * When you change something in filters section whether it should auto apply filters or not.
   * If this is set to false "Apply Filters" button will be visible
   *
   * @type {boolean}
   */
  autoApplyFilters: true,

  /**
   * Whether to show full screen button on image
   *
   * @type {boolean}
   */
  fullScreenButtonOnImage: false,

  /**
   * You can disable opening image on full screen by clicking on the image, instead you can use `fullScreenButtonOnImage`
   * option to give users possibility to expand the image on full screen
   *
   * @type {boolean}
   */
  dontOpenOnImageClick: false,

  /**
   * Whether to show back to list button on image
   *
   * @type {boolean}
   */
  backButtonOnImage: false,

  /**
   * Whether to enable "Add to watchlist" button or not
   *
   * @type {boolean}
   */
  enableNotifyMeOnLotStart: false,

  /**
   * The home page URL of the application.
   * If you leave this null it will use Webapp's default home page address
   *
   * @type {string}
   */
  homeUrl: '',

  /**
   * Google tag manager configuration options for [Vue Gtag package](https://matteo-gabriele.gitbook.io/vue-gtag/)
   *
   * @type {object}
   */
  gtag: {
    config: {
      id: null
    }
  },

  /**
   * Available options to be displayed under "Lot Status" filter criteria for filtering lots
   * All available options are:
   *  - `all` - Will display all lots
   *  - `upcoming` - Will display only lots that are published, but start date is in the future
   *  - `open` - Will display only lots that are published and started
   *  - `published` - Will display only lots that are published
   *  - `sold` - Will display only sold lots
   *  - `unsold` - Will display only unsold lots
   *  - `passed` - Will display all sold and unsold lots
   *
   * @type {Array}
   */
  filterLotStatusOptions: [
    'all',
    'published',
    'sold',
    'unsold',
  ],

  /**
   * Default lot status selected in "Lot Status" filter criteria for filtering lots
   *
   * @type {string}
   */
  defaultLotStatus: 'published',

  /**
   * Per page dropdown options, that will be displayed in every per page dropdown.
   * Maximum supported value is 100.
   * Any value above 100 will be removed from this list and not be displayed
   *
   * @type {Array}
   */
  perPageOptions: [5, 10, 25, 50, 100],

  /**
   * Whether to show bidding limit for bidders or not.
   * Please consider that this only makes sense if bidding limits are configured and set for bidders,
   * Even if you set this true, but no bidding limits are configured nothing will be displayed
   *
   * @type {boolean}
   */
  showBiddingLimit: true,

  /**
   * Whether to display personal identification fields in registration or not
   *
   * @type {boolean}
   */
  personalIdentificationDetails: false,

  /**
   * Whether to display bank account fields in registration or not
   *
   * @type {boolean}
   */
  bankAccountDetails: false,

  /**
   * Whether to show or not current reserve status
   *
   * @type {boolean}
   */
  showReserveStatus: true,

  /**
   * Number of minutes before the live auction start date to display live auction bidding button
   *
   * @type {number}
   */
  liveBiddingButtonShowTime: 15,

  /**
   * Number of minutes before the live auction start date to hide commission bid buttons
   *
   * @type {number}
   */
  liveCommissionButtonHideTime: 15,

  /**
   * Whether to show or not auction type in auction listing or in auction inner page
   *
   * @type {boolean}
   */
  showAuctionType: false,

  /**
   * Setting true here will display 3 main numbers in confirmation dialog when user tries to make a bid.
   *  - Bid Amount
   *  - Commission (Calculated based on premiums)
   *  - Total Amount (Bid Amount + Commission)
   *
   * @type {boolean}
   */
  showBidAmountsInConfirmation: false,

  /**
   * By default filters are displayed staticly on the place where it is configured.
   * If you set this true, only filters button will be displayed and clicking on it will show filters overlay over content
   *
   * @type {boolean}
   */
  overlayFilters: false,

  /**
   * Image thumbnail carousel position on lot view page
   * Possible options: 'left', 'bottom'
   *
   * @type {string}
   */
  imageCarouselPosition: 'bottom',

  /**
   * Whether to show lots count when the value is 0 in auctions list.
   *
   * @type {boolean}
   */
  showZeroLotCount: true,

  /**
   * Whether to show 'bidding is open' badge for auction in auctions list
   *
   * @type {boolean}
   */
  showAuctionBiddingOpenBadge: true,

  /**
   * Whether to show Buy Now button and enable Buy now functionality on published auctions when bidding is in progress
   * Warning! This will not show buy now button on live auctions.
   *
   * @type {boolean}
   */
  enableBuyNowOnPublishedAuction: false,

  /**
   * Activating this option is good idea only for those auction houses who have only single department
   *
   * @type {boolean}
   */
  alwaysSelectFirstDepartment: false,

  /**
   * An option that determines how many minutes before the lot closes the phone number will be displayed when sending an inquiry
   */
  inquiryTimeAnchor: null,

  /**
   * Whether to group lots by auction on my bids page
   *
   * @type {boolean}
   */
  groupLotsOnMyBidsPage: false,

  /**
   * Whether to group lots by auction on my bids page
   *
   * @type {boolean}
   */
  groupLotsOnFavouritesPage: false,

  /**
   * If this is set to true and if auction was not marked otherwise, bidder will be registered automatically
   * when opening the lot inner view.
   * If this is set to false then "Register as bidder" button will become visible and bidder will not
   * be automatically registered on the sale.
   *
   * Then this setting losses the purpose if one of the following fields was checked on auction from Artisio:
   *  - "Require Bidder Approval"
   *  - "Request Deposit"
   *  - "Require Credit Card Verification"
   *
   * @type {boolean}
   */
  allowBidderAutoRegistration: true,

  /**
   * To scroll at the top of the lot/auction listing when page changes with pagination buttons
   *
   * @type {boolean}
   */
  scrollToTopOnPageChange: true,

  /**
   * Google Maps Load configuration object
   *
   * Available configuration options can be found here
   * https://github.com/xkjyeah/vue-google-maps
   *
   * @type {object}
   */
  googleMapsLoadConfig: {
    key: ''
  },

  /**
   * If this is set to true and if auction is live, bidder will be able to place telephone bid
   * @type {boolean}
   */
  enableTelephoneBidding: false,

  /**
   * Number of minutes before the live auction start date to hide register as telephone bidder button
   *
   * @type {number}
   */
  telephoneBidderButtonHideTime: 60,

  /**
   * Vee Validate package validation messages
   *
   * {
   *   "alpha": "The {_field_} field may only contain alphabetic characters",
   *   "alpha_num": "The {_field_} field may only contain alpha-numeric characters",
   *   "alpha_dash": "The {_field_} field may contain alpha-numeric characters as well as dashes and underscores",
   *   "alpha_spaces": "The {_field_} field may only contain alphabetic characters as well as spaces",
   *   "between": "The {_field_} field must be between {min} and {max}",
   *   "confirmed": "The {_field_} field confirmation does not match",
   *   "digits": "The {_field_} field must be numeric and exactly contain {length} digits",
   *   "dimensions": "The {_field_} field must be {width} pixels by {height} pixels",
   *   "email": "The {_field_} field must be a valid email",
   *   "excluded": "The {_field_} field is not a valid value",
   *   "ext": "The {_field_} field is not a valid file",
   *   "image": "The {_field_} field must be an image",
   *   "integer": "The {_field_} field must be an integer",
   *   "length": "The {_field_} field must be {length} long",
   *   "max_value": "The {_field_} field must be {max} or less",
   *   "max": "The {_field_} field may not be greater than {length} characters",
   *   "mimes": "The {_field_} field must have a valid file type",
   *   "min_value": "The {_field_} field must be {min} or more",
   *   "min": "{_field_} German {length}",
   *   "numeric": "The {_field_} field may only contain numeric characters",
   *   "oneOf": "The {_field_} field is not a valid value",
   *   "regex": "The {_field_} field format is invalid",
   *   "required_if": "The {_field_} field is required",
   *   "required": "The {_field_} field is required",
   *   "size": "The {_field_} field size must be less than {size}KB",
   *   "double": "The {_field_} field must be a valid decimal"
   * }
   *
   * @type {object}
   */
  veeValidateMessages: {},

  /**
   * Countdown duration translations. Where the key is the language code and the value is object containing one of the
   * available duration characters: d, h, m, s
   *
   * The default values:
   * d - days
   * h - hours
   * m - minutes
   * s - seconds
   *
   * Example:
   * This will only override d and use j instead of d in fr language code.
   * countdownDurationTranslations: {
   *   fr: {
   *     "d": "j"
   *   }
   * }
   */
  countdownDurationTranslations: {}
};

export const STRING_OPTIONS = [
  "clientId",
  "lang",
  "defaultPage",
  "loginUrl",
  "signupUrl",
  "translationsUrl",
  "visibleForGuestsSelector",
  "visibleForAuthSelector",
  "artisioCookieName",
  "branchUuid",
  "paginationType",
  "lotDefaultSortField",
  "lotDefaultSortBy",
  "auctionDefaultSortField",
  "auctionDefaultSortBy",
  "lotPaginationLimit",
  "auctionPaginationLimit",
  'termsAndConditionsHtml',
  'termsAndConditionsUrl',
  'termsAndConditionsVersion',
  "currencyFormat",
  "dateFormat",
  "timeFormat",
  "timeFormatWithSeconds",
  "datetimeFormat",
  "datetimeFormatWithSeconds",
  "makeAnEnquiryEmail",
  "homeUrl",
  "preset",
  "defaultLotStatus",
  "liveBiddingButtonShowTime",
  "liveCommissionButtonHideTime",
  "imageCarouselPosition",
  'inquiryTimeAnchor',
  'googleMapsKey',
  "telephoneBidderButtonHideTime"
];
export const BOOLEAN_OPTIONS = [
  "socialShares",
  "jumpToLot",
  "showBreadcrumbs",
  "showPrevNextList",
  "showLatestBids",
  "enableWatchlist",
  "perPageSelect",
  "enableAuth",
  "enableBidding",
  "enableFilters",
  "enableSorting",
  "showCountdown",
  "descriptionInSidebar",
  "showMoreInDescription",
  "sharesInSidebar",
  "enablePrivateTreaty",
  "showCollectionInfo",
  "hideDepartmentsIfSelected",
  "autoApplyFilters",
  "fullScreenButtonOnImage",
  "dontOpenOnImageClick",
  "backButtonOnImage",
  "enableNotifyMeOnLotStart",
  "showBiddingLimit",
  "personalIdentificationDetails",
  "bankAccountDetails",
  "alwaysShowCategories",
  "showReserveStatus",
  "showAuctionType",
  "showBidAmountsInConfirmation",
  "overlayFilters",
  "showZeroLotCount",
  "showAuctionBiddingOpenBadge",
  "enableBuyNowOnPublishedAuction",
  "alwaysSelectFirstDepartment",
  "groupLotsOnMyBidsPage",
  "groupLotsOnFavouritesPage",
  "allowBidderAutoRegistration",
  "scrollToTopOnPageChange",
  "enableTelephoneBidding"
];

export const OBJECT_OPTIONS = [
  'translations',
  'templates',
  'gtag',
  'filterLotStatusOptions',
  'perPageOptions',
  'googleMapsLoadConfig',
  'veeValidateMessages',
  'countdownDurationTranslations'
];

export default DEFAULT_CONFIG;
