import Vue from 'vue'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import vueDebounce from 'vue-debounce'
import VueCookie from 'vue-cookie';
import VueNativeSock from 'vue-native-websocket'
import VueGtag from "vue-gtag";
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import VueNumberFormat from '@coders-tm/vue-number-format'
import VueBootstrapAutocomplete from '@vue-bootstrap-components/vue-bootstrap-autocomplete';

import moment from 'moment';
import * as VueGoogleMaps from 'vue2-google-maps'

import App from './App.vue'
import router from './router'
import store from "./store";
import i18n from './i18n';
import './filters';
import './scss/main.scss'
import './components/configuration'
import './plugins'
import {afterAuthFinish} from "./helpers";
import CONFIG from "./user-config";
import {SOCKET_RECONNECT_DELAY} from "@/constants";

moment.locale(CONFIG.lang)

Vue.config.productionTip = false

// Global registration
Vue.component('vue-bootstrap-autocomplete', VueBootstrapAutocomplete)
Vue.use(VueNumberFormat, {precision: 4})
Vue.use(VueTelInput);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(vueDebounce)
Vue.use(VueCookie);
Vue.use(VueNativeSock, process.env.VUE_APP_WEBSOCKET_SERVER, {
  store: store,
  passToStoreHandler: (eventName, event, next) => {
    store.commit('updateSocketLastInteractionTime');
    next(eventName, event);
  },
  format: 'json',
  reconnection: true, // (Boolean) whether to reconnect automatically (false)
  // reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
  reconnectionDelay: SOCKET_RECONNECT_DELAY, // (Number) how long to initially wait before attempting a new (1000)
})
if (CONFIG.gtag.config.id) {
  Vue.use(VueGtag, {
    pageTrackerExcludedRoutes: [
      'LotView'
    ],
    pageTrackerTemplate(to) {
      return {
        page_title: to.fullPath,
        page_path: to.fullPath
      }
    },
    config: {id: CONFIG.gtag.config.id}
  }, router);
}

Vue.use(VueGoogleMaps, {
  load: CONFIG.googleMapsLoadConfig,

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true
})

document.body.classList.add(`artisio-webapp-preset-${CONFIG.preset || 'default'}`)

store.dispatch('getSettings');

store.dispatch('socketLoginCallback')
  .then(() => {
    afterAuthFinish(store.state.is_authorized)

    store.state.user.data = JSON.parse(localStorage.getItem('user'));
    store.state.user.token = Vue.prototype.$cookie.get(CONFIG.artisioCookieName);
  })

window.artisio = window.artisio || {};

let el = document.getElementById('artisioTimedAuctions')
const init = () => {
  if (!el) {
    el = document.getElementById('artisioTimedAuctions')
  }
  if (!el) {
    console.error(`Artisio Webapp HTML Element with id "artisioTimedAuctions" does not exist`)
  } else {
    let innerDiv = el.children[0];
    innerDiv = innerDiv || document.createElement('div');
    el.appendChild(innerDiv);
    window.artisio.app = new Vue({
      router,
      store,
      i18n,
      render: h => h(App)
    }).$mount(innerDiv)
  }
}

const destroy = () => {
  if (window.artisio.app) {
    window.artisio.app.$destroy()
    window.artisio.app = null;
  }
  if (!el) {
    el = document.getElementById('artisioTimedAuctions')
  }
  if (el) {
    el.innerHTML = '';
  }
}

window.artisio.init = init;
window.artisio.destroy = destroy

init();
