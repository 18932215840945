<template>
  <div v-if="mainConfig.enableTelephoneBidding && activeAuction.type === 'live'"
       class="ta-register-as-telephone-bidder-wrapper">
    <div v-if="shouldBeHidden" class="ta-telephone-bidder-closed">
      {{ $t('Telephone bidder registration is already finished.') }}
    </div>
    <template v-else>
      <b-button
          v-if="forAuction && auction.is_approved_bidder"
          class="ta-register-as-telephone-bidder-button"
          @click="openModal()"
          :variant="buttonVariant"
          :size="buttonSize"
          :block="block">
        {{ label }}
      </b-button>
      <div v-else-if="forLot && lot.auction.is_approved_bidder">
        <b-button
            v-if="!lot.pre_bid"
            class="ta-register-as-telephone-bidder-button btn-block"
            @click="openModal()"
            :variant="buttonVariant"
            :size="buttonSize"
            :block="block">
          {{ label }}
        </b-button>
        <div v-if="lot.pre_bid && lot.pre_bid.type === 'telephone'">
          <div v-if="lot.pre_bid.status === 'pending'" class="alert alert-warning ta-telephone-bidder-pending-txt">
            {{
              $t('You have already registered as a telephone bidder for this lot and your registration is currently pending approval')
            }}
          </div>
          <div v-else-if="lot.pre_bid.status === 'confirmed'"
               class="alert alert-info ta-telephone-bidder-confirmed-txt">
            {{ $t('You have already registered as a telephone bidder for this lot.') }}
          </div>
          <div v-else-if="lot.pre_bid.status === 'rejected'"
               class="alert alert-danger ta-telephone-bidder-rejected-txt">
            {{
              $t('Unfortunately, your request to register as a telephone bidder for this lot has been rejected. Please contact us if you have any further questions')
            }}
          </div>
          <div class="row" v-if="lot.pre_bid.status !== 'rejected'">
            <div class="col-6">
              <b-button @click="onDeleteClick" class="ta-telephone-bid-delete-btn" variant="danger" :size="buttonSize"
                        :block="block">
                {{ $t('Delete') }}
              </b-button>
            </div>
            <div class="col-6">
              <b-button class="ta-telephone-bid-edit-btn" @click="openModal(true)" :variant="buttonVariant"
                        :size="buttonSize" :block="block">
                {{ $t('Edit') }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <ValidationObserver ref="telephoneBidderValidationObserver" v-slot="{errors, invalid, handleSubmit}"
                          v-if="showModal">
        <b-modal ref="telephoneBidModal"
                 @hide="closeModel"
                 :visible="showModal"
                 size="lg"
                 no-close-on-esc
                 no-close-on-backdrop
                 @shown="onModalShown"
                 @ok.prevent="handleSubmit(onSubmitClick)"
                 static
                 lazy>


          <template v-slot:modal-header>
            <h5 class="modal-title" v-html="modalTitle"></h5>
            <button type="button" @click="closeModel" aria-label="Close" class="close">×
            </button>
          </template>

          <b-overlay :show="loading" rounded="sm">
            <form ref="telephoneBidderForm" autocomplete="off">
              <div class="mb-4">
                <ValidationProvider ref="lotTypeAheadProvider" rules="required" v-slot="{errors}" name="lotUuid" vid="lotUuid">
                  <lot-type-ahead v-if="forAuction"
                                  ref="lotTypeAhead"
                                  :auction-uuid="auction.uuid"
                                  :input-class="errors.length ? 'is-invalid' : ''"
                                  @input="onLotSelect"/>
                  <div v-if="errors.length" class="d-block invalid-feedback">
                    {{ $t('Please Select a Lot') }}
                  </div>
<!--                  Don't delete this hidden field. Without it vee validate does not hide the error-->
                  <input type="hidden" v-model="model.lot_uuid">
                </ValidationProvider>
              </div>

              <div class="ta-confirmation-lot-details">
                <div v-if="activeLot && activeLot.uuid" class="row mb-3 align-items-center">
                  <div class="col-sm-3">
                    <b-img v-if="activeLot.image_urls.length" :src="activeLot.image_urls[0]"
                           :alt="$options.filters.stripTags($options.filters.currentLocale(activeLot.title))"
                           fluid
                           thumbnail/>
                    <b-img v-else src="https://cdn.artisio.co/no_image.jpg" fluid thumbnail/>
                  </div>
                  <div class="col-sm-9">
                    <h6>{{ $t('Lot no:')}} {{ activeLot.lot_no}}</h6>
                    <h5 v-html="$options.filters.stripTags($options.filters.currentLocale(activeLot.title))">
                    </h5>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-4">
                  <InputWidget
                      v-model="model.amount"
                      :error="errors.amount"
                      id="idAmountInput"
                      :label="$t('Cover Amount')"
                      type="number"
                      rules="required|min:0"
                  />
                </div>
                <div class="col-sm-12 col-md-4">
                  <InputWidget
                      v-model="model.phone"
                      :error="errors.phone"
                      id="idPhoneInput"
                      vid="idPhoneInput"
                      :type="'phone'"
                      :label="$t('Phone 1')"
                      :rules="'required|min:10'"
                  >
                  </InputWidget>
                </div>
                <div class="col-sm-12 col-md-4">
                  <InputWidget
                      v-model="model.phone2"
                      :error="errors.phone2"
                      id="idPhone2Input"
                      :type="'phone'"
                      :label="$t('Phone 2')"
                      :rules="'min:10'"
                  ></InputWidget>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md">
                  <InputWidget
                      v-model="model.description"
                      :error="errors.description"
                      id="idDescriptionInput"
                      :type="'textarea'"
                      :label="$t('Comment')"
                  ></InputWidget>
                </div>
              </div>
            </form>
          </b-overlay>

          <template v-slot:modal-footer>
            <b-button variant="secondary" @click="closeModel">
              {{ $t('Cancel') }}
            </b-button>
            <LoadingButton variant="primary" @click.prevent="handleSubmit(onSubmitClick.bind(null, true))" :loading="loading">
              {{ $t('Save') }}
            </LoadingButton>
            <LoadingButton variant="primary" @click.prevent="handleSubmit(onSubmitClick.bind(null, false))" :loading="loading" v-if="forAuction">
              {{ $t('Save and Add Another') }}
            </LoadingButton>
          </template>

        </b-modal>
      </ValidationObserver>
    </template>
  </div>
</template>
<script>
import moment from "moment";
import {mapActions, mapState} from 'vuex';
import LoadingButton from "@/components/core/LoadingButton.vue";
import InputWidget from "@/components/core/InputWidget.vue";
import LotTypeAhead from "@/components/core/LotTypeAhead.vue";
import {getCurrentServerTime} from "@/helpers";

export default {
  name: "BiddingTelephoneBid",
  components: {LotTypeAhead, InputWidget, LoadingButton},
  props: {
    icon: {
      type: Boolean,
      default: () => true
    },
    buttonVariant: {
      type: String,
      default: () => 'primary' // All valid bootstrap 4 button variants
    },
    buttonSize: {
      type: String,
      default: () => 'md' // sm, md, lg
    },
    block: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default() {
        return this.$t('Register as a Telephone Bidder')
      }
    }
  },
  computed: {
    ...mapState(['mainConfig', 'customer']),
    ...mapState({
      auction: state => state.auctionObject.data,
      lot: state => state.lotObject.data,
    }),
    activeAuction() {
      return this.auction || this.lot.auction
    },
    activeLot() {
      return this.selectedLot || this.lot
    },
    forAuction() {
      return !this.lot?.uuid && this.auction
    },
    forLot() {
      return this.lot && this.lot.uuid && this.lot.auction
    }
  },
  data: () => ({
    showModal: false,
    modalTitle: '',
    loading: false,
    model: {
      lot_uuid: '',
      amount: '',
      phone: '',
      phone2: '',
      description: ''
    },
    errors: {
      lot_uuid: null,
      amount: null,
      phone: null,
      phone2: null,
      description: null
    },
    selectedLot: null,
    interval: null,
    difference: null,
    shouldBeHidden: false,
    oldData: ''
  }),
  methods: {
    ...mapActions(['createTelephoneBid', 'deleteTelephoneBid']),
    onLotSelect($event) {
      this.selectedLot = $event
      this.model.lot_uuid = this.selectedLot.uuid
    },
    async closeModel($event, force = false) {
      const newData = JSON.stringify(this.model)
      if (!force && newData !== this.oldData) {
        const result = await this.$confirm(this.$t('If you close the modal you will lose unsaved data. Do you want to proceed?'))
        if (result) {
          this.closeModel($event, true)
        }
        return;
      }
      this.$refs.telephoneBidderForm.reset();
      this.showModal = false;
      this.loading = false;
      this.model = {
        lot_uuid: '',
        amount: '',
        phone: '',
        phone2: '',
        description: ''
      };
      this.selectedLot = null
    },
    openModal(edit = false,) {
      this.showModal = true;
      this.model.phone = this.customer.phone
      if (this.forAuction) {
        this.modalTitle = this.$t('Register as a Telephone Bidder');
      } else {
        this.model.lot_uuid = this.activeLot.uuid;
        this.modalTitle = this.$t('Register as a Telephone Bidder');
        if (edit) {
          this.modalTitle = this.$t('Edit a Telephone Bidder');
          this.model.amount = this.activeLot.pre_bid.amount;
          this.model.phone = this.activeLot.pre_bid.phone;
          this.model.phone2 = this.activeLot.pre_bid.phone2;
          this.model.description = this.activeLot.pre_bid.description;
        }
      }

      this.oldData = JSON.stringify(this.model)
    },
    async onSubmitClick(close) {
      const res = await this.createTelephoneBid({
        lot_uuid: this.activeLot.uuid,
        type: 'telephone',
        amount: this.model.amount,
        phone: this.model.phone,
        phone2: this.model.phone2,
        description: this.model.description,
      })
      if (res.success && close) {
        this.closeModel(null, true)
      } else if (res.success) {
        this.$refs.telephoneBidderValidationObserver.reset();
        this.model.lot_uuid = null
        this.model.amount = '';
        this.selectedLot = null;
        this.$refs.lotTypeAhead.reset();

        this.oldData = JSON.stringify(this.model)
      }
    },

    onModalShown() {

      // console.log( this.lot.pre_bid)
      // this.$refs.telephoneBidderValidationObserver.validate()
    },
    async onDeleteClick() {
      const result = await this.$confirm(this.$t('Are you sure you want to delete this Telephone Bid?'))
      if (result) {
        this.deleteTelephoneBid({uuid: this.lot.uuid})
      }
    },
    updateTimeDifference() {
      let startDate = (this.auction || this.lot.auction).start_date;

      let eventDate = typeof startDate === "string" ? moment(startDate).toDate().getTime() : startDate.getTime();
      startDate = moment(eventDate).utc();
      const currentTime = getCurrentServerTime()

      this.difference = startDate - currentTime

      this.shouldBeHidden = this.difference <= this.mainConfig.telephoneBidderButtonHideTime * 60 * 1000
    },
  },
  mounted() {
    this.updateTimeDifference();
    const interval = 1000;

    this.interval = setInterval(() => {
      this.updateTimeDifference();
    }, interval);
  },
  destroyed() {
    clearInterval(this.interval);
  },
}
</script>

<style lang="scss" scoped>
@import "../../../bootstrap/import";

.ta-register-as-telephone-bidder-wrapper {
  margin-bottom: 1rem;
}

.ta-telephone-bid-delete-btn, .ta-telephone-bid-edit-btn {
  width: 100%;
}

.ta-telephone-bidder-closed {
  display: inline-block;
  padding: 2rem 0;
  color: $gray-600;
  text-align: center;
}
</style>
