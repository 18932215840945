<template>
  <div class="ta-page-items">
    <PageHeader :title="$t(`My Telephone Bids`)"></PageHeader>
    <div class="d-flex justify-content-start">
      <div class="ta-item-list">
        <div class="ta-item-list-header">
          <div class="ta-per-page-wrapper">
            {{ $t("Show") }}
            <b-form-select class="mx-2" v-model="pagination.limit" @change="updatePagination" :options="perPageOptions"/>
            {{ $t("entries") }}
          </div>
        </div>
        <div v-if="loading" class="ta-item-loader">
          <b-spinner label="Spinning"></b-spinner>
          <span class="mt-2">{{ $t("Loading...") }}</span>
        </div>
        <template v-if="!loading">
          <div class="ta-item-list-wrapper">
            <table class="table">
              <thead>
              <tr class="text-nowrap">
                <th scope="col">{{ $t("Thumbnail") }}</th>
                <th scope="col">{{ $t("Sale / Lot") }}</th>
                <th scope="col">{{ $t("Title") }}</th>
                <th scope="col">{{ $t("Phone 1/Phone 2") }}</th>
                <th scope="col">{{ $t("Cover Amount") }}</th>
                <th scope="col">{{ $t("Comment") }}</th>
                <th scope="col">{{ $t("Status") }}</th>
                <th scope="col">{{ $t("Actions") }}</th>
              </tr>
              </thead>
              <tbody v-if="results.length">
              <tr v-for="bid in results" :key="bid.uuid">
                <td>
                  <b-img width="100"
                         :src="bid.image_urls.length > 0 ? bid.image_urls[0] : 'https://cdn.artisio.co/no_image.jpg'"
                         fluid></b-img>
                </td>
                <td class="text-nowrap">
                  <router-link :to="{ name: 'AuctionViewTimed', params: { uuid: bid.auction_uuid }}">{{ bid.auction_no }}</router-link>
                  /
                  <router-link :to="{ name: 'LotView', params: { lotno: bid.lot_uuid }}">{{ bid.lot_no }}</router-link>
                </td>
                <td v-html="$options.filters.stripTags($options.filters.currentLocale(bid.lot_title))"></td>
                <td>
                  {{ bid.phone }}
                  <br>
                  {{ bid.phone2 }}
                </td>
                <td>{{ bid.amount | asCurrency(bid.currency_code)}}</td>
                <td>{{ bid.description }}</td>
                <td>
                  <small class="badge" :class="getClassByStatus(bid.status)">
                    {{ capitalizeWord(bid.status) }}
                  </small>

                </td>
                <td class="text-nowrap">
                  <router-link :to="{ name: 'LotView', params: { lotno: bid.lot_uuid }}" class="btn btn-light btn-sm">
                    <b-icon icon="eye" aria-hidden="true"/>
                    {{ $t("Open Lot") }}
                  </router-link>
                </td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td colspan="10">
                  <p class="text-muted text-center py-3">
                    {{ $t(`You don't have data`) }}
                  </p>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="ta-item-list-footer">
            <div class="ta-item-pagination-summary">
              {{
                $t("Showing {start} to {finish} of {total} entries", {
                  start:
                    (pagination.page - 1) * pagination.limit +
                    1,
                  finish:
                    pagination.page * pagination.limit > totalRows
                      ? totalRows
                      : (pagination.page * pagination.limit),
                  total: totalRows,
                })
              }}
            </div>
            <b-pagination
              v-if="totalRows > pagination.limit"
              v-model="pagination.page"
              :total-rows="totalRows"
              :per-page="pagination.limit"
              @input="updatePagination"
              class="ta-item-list-pagination"
            ></b-pagination>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'

  import {mapState, mapGetters, mapActions} from "vuex";
  import {
    ITEM_PAGINATION_PAGE,
    ITEM_PAGINATION_LIMIT,
  } from "../constants";
  import logger from "../logger";
  import {capitalizeWord, getCurrentServerTime} from "../helpers";
import PageHeader from "@/components/PageHeader";
  import axiosClient from "@/services/axios";

  export default {
    name: "MyTelephoneBids",
    components: {
      PageHeader
    },
    data: () => {
      return {
        capitalizeWord: capitalizeWord,
        loading: false,
        pagination: {
          page: ITEM_PAGINATION_PAGE,
          limit: ITEM_PAGINATION_LIMIT,
        },
        totalRows: 5,
        results: [],
      };
    },

    computed: {
      ...mapState(["perPageOptions", 'mainConfig']),
      ...mapGetters(["currentLocale"]),
    },
    watch: {
      "$route.query": {
        immediate: true,
        handler(newVal) {
          // make actions with newVal.page
          if (newVal.page) this.pagination.page = newVal.page;
          if (newVal.limit) this.pagination.limit = newVal.limit;

          this.getUpdatedItems();
        },
      },
    },

    methods: {
      ...mapActions(["deleteTelephoneBid",]),
      getClassByStatus(status) {
        const map = {
          "rejected": "badge-danger",
          "confirmed": "badge-success",
          "pending": "badge-warning",
        }
        return map[status] ? map[status] : ''
      },

      updatePagination() {
        this.updateRoute();
      },

      updateRoute() {
        this.$router
          .push({
            path: this.$route.path,
            query: {
              ...this.$route.query,
              page: this.pagination.page,
              limit: this.pagination.limit,
              ...this.filters,
            },
          })
          .catch((err) => {
            logger.error("Failed to update route for items", err);
          });
      },

      getUpdatedItems() {
        this.loading = true
        axiosClient.get('/website/telephone-bids', {
          params: {
            page: this.pagination.page,
            limit: this.pagination.limit,
            ...this.filters
          }
        })
          .then(({data, status}) => {
            this.loading = false;
            this.results = data.results;
            this.totalRows = data.count
          })
          .catch(err => {
            this.loading = false;
          })
      },
    },
  };
</script>

<style scoped lang="scss">
  .ta-item-list-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .ta-item-loader {
    height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .ta-per-page-wrapper {
    display: flex;
    align-items: center;
  }

  .ta-item-list {
    flex: 1;
  }

  .ta-item-list-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ta-uc-first {
    text-transform: capitalize;
  }

  .pagination.ta-item-list-pagination {
    margin-bottom: 0 !important;
  }
</style>
