<template>
  <filter-group :title='$t("Winning/Losing")' id="collapse-winning-or-losing" :collapsed="collapsed">
    <b-form-group>
      <b-form-radio v-model="winning" :value="null">{{$t('All')}}</b-form-radio>
      <b-form-radio v-model="winning" :value="'true'">{{$t('Winning/Won')}}</b-form-radio>
      <b-form-radio v-model="winning" :value="'false'">{{$t('Losing/Lost')}}</b-form-radio>
    </b-form-group>
  </filter-group>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import {LOT_LIST_DEFAULT_PAGE} from "@/constants";
import {updateLotsRoute} from "@/helpers";
import FilterGroup from "../../FilterGroup";
import userConfig from "@/user-config";

export default {
  name: "FilterWinningOrLosing",
  components: {FilterGroup},
  props: {
    collapsed: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    winning: null
  }),
  computed: {
    ...mapState(['lotQueryParams']),
  },
  watch: {
    lotQueryParams() {
      this.winning = this.lotQueryParams.winning
    },
    winning() {
      this.filtersChanged()
    }
  },
  methods: {
    ...mapMutations(['setLotFilters']),
    filtersChanged() {
      this.setLotFilters({
        winning: this.winning,
        page: LOT_LIST_DEFAULT_PAGE,
      });
      if (userConfig.autoApplyFilters) {
        updateLotsRoute();
      }
    }
  },
  mounted() {
    this.winning = this.lotQueryParams.winning
  },
  destroyed() {
    this.setLotFilters({
      winning: null,
    });
  }
}
</script>
