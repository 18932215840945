<template>
  <div v-if="mainConfig.termsAndConditionsVersion && (mainConfig.termsAndConditionsUrl || mainConfig.termsAndConditionsHtml)" class="col-sm-12 col-md d-flex">
    <InputWidget
      v-model="model"
      :error="errors"
      id="input-terms_and_conditions"
      type="checkbox"
      @change="inputChange"
      @input="onInput"
      :checkbox_checked_value="true"
      :placeholder="computedPlaceholder"
      rules="required"
    >
      <template v-if="$slots.default">
        <slot></slot>
      </template>
      <template v-slot:label>
        {{ $t('I have read and agree to the') }} <a :href="mainConfig.termsAndConditionsUrl || '#'" target="_blank" @click="onClick">
        {{ defaultLabel }}
      </a>.
      </template>
    </InputWidget>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex'
import BaseInputMixin from "../../../mixins/signupInputMixin";
import InputWidget from "../../core/InputWidget";

export default {
  name: "TermsAndConditions",
  components: {InputWidget},
  mixins: [BaseInputMixin('terms_and_conditions')],
  data() {
    return {
      defaultLabel: this.$t("Terms and Conditions")
    }
  },
  computed: {
    ...mapState(['mainConfig'])
  },
  methods: {
    ...mapMutations(['setTermsAndConditionsModal']),
    onClick(ev) {
      if (this.mainConfig.termsAndConditionsUrl && !this.mainConfig.termsAndConditionsHtml) {
        return;
      }
      ev.preventDefault();
      this.setTermsAndConditionsModal({
        show: true,
        title: this.defaultLabel,
      })
    }
  }
}
</script>

<style scoped>

</style>
