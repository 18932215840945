import CONFIG from "../user-config";
import {
  AUCTION_DEFAULT_SORT_BY,
  AUCTION_DEFAULT_SORT_FIELD,
  AUCTION_DEFAULT_STATUS,
  AUCTION_LIST_DEFAULT_LIMIT,
  AUCTION_LIST_DEFAULT_PAGE,
  FAVORITE_AUCTION_DEFAULT_SORT_BY,
  FAVORITE_AUCTION_DEFAULT_SORT_FIELD,
  FAVORITE_AUCTION_DEFAULT_STATUS,
  FAVORITE_AUCTION_LIST_DEFAULT_LIMIT,
  FAVORITE_AUCTION_LIST_DEFAULT_PAGE,

  FAVORITE_LOT_DEFAULT_SORT_BY,
  FAVORITE_LOT_DEFAULT_SORT_FIELD,
  FAVORITE_LOT_DEFAULT_STATUS,
  FAVORITE_LOT_LIST_DEFAULT_LIMIT,
  FAVORITE_LOT_LIST_DEFAULT_PAGE,

  I_MADE_BID_LOT_DEFAULT_SORT_BY,
  I_MADE_BID_LOT_DEFAULT_SORT_FIELD,
  I_MADE_BID_LOT_DEFAULT_STATUS,
  I_MADE_BID_LOT_LIST_DEFAULT_LIMIT,
  I_MADE_BID_LOT_LIST_DEFAULT_PAGE,

  INVOICE_PAGINATION_LIMIT,
  INVOICE_PAGINATION_PAGE,
  ITEM_PAGINATION_LIMIT,
  ITEM_PAGINATION_PAGE,
  LOT_DEFAULT_SORT_BY,
  LOT_DEFAULT_SORT_FIELD, LOT_DEFAULT_STATUS,
  LOT_LIST_DEFAULT_LIMIT,
  LOT_LIST_DEFAULT_PAGE,
  SETTLEMENT_PAGINATION_LIMIT,
  SETTLEMENT_PAGINATION_PAGE,
  TEMPORARY_ITEM_PAGINATION_LIMIT,
  TEMPORARY_ITEM_PAGINATION_PAGE
} from "../constants";
import store from "@/store/index";
import i18n from "../i18n";

let doNotShowConfirmOnBid = localStorage.getItem('ARTISIO_DO_NOT_SHOW_CONFIRM_ON_BID') || null;
if (doNotShowConfirmOnBid) {
  doNotShowConfirmOnBid = JSON.parse(doNotShowConfirmOnBid)
}

export const LOT_DEFAULT_FILTERS = {
  q: '',
  department: [],
  category: [],
  reserve_low: '',
  reserve_high: '',
  estimate_low: '',
  estimate_high: '',
  withImages: false,
  lot_no_from: '',
  lot_no_to: '',
  collection_info_states: [],
  collection_info_municipalities: [],
  page: LOT_LIST_DEFAULT_PAGE,
  limit: LOT_LIST_DEFAULT_LIMIT,
  sort: LOT_DEFAULT_SORT_FIELD,
  'sort-by': LOT_DEFAULT_SORT_BY,
  winning: null,
  lot_status: LOT_DEFAULT_STATUS,
  auction_type: 'all'
};

export const AUCTION_DEFAULT_FILTERS = {
  q: '',
  department: [],
  category: [],
  page: AUCTION_LIST_DEFAULT_PAGE,
  limit: AUCTION_LIST_DEFAULT_LIMIT,
  sort: AUCTION_DEFAULT_SORT_FIELD,
  'sort-by': AUCTION_DEFAULT_SORT_BY,
  status: AUCTION_DEFAULT_STATUS
}

export const FAVORITE_AUCTION_DEFAULT_FILTERS = {
  page: FAVORITE_AUCTION_LIST_DEFAULT_PAGE,
  limit: FAVORITE_AUCTION_LIST_DEFAULT_LIMIT,
  sort: FAVORITE_AUCTION_DEFAULT_SORT_FIELD,
  'sort-by': FAVORITE_AUCTION_DEFAULT_SORT_BY,
  status: FAVORITE_AUCTION_DEFAULT_STATUS
}

export const PROFILE_DEFAULT_VALUE = {
  initials: "",
  first_name: "",
  middle_name: "",
  last_name: "",
  gender: null,
  title: null,
  preferred_language_code: "",
  email: "",
  company_name: "",
  is_company: false,
  subscribe_to_newsletter: false,
  personal_id: "",
  phone: "",
  old_password: "",
  password: "",
  password_confirmation: "",
  shipping_address: {
    address_1: "",
    address_2: "",
    country_code: "",
    state: "",
    city: "",
    zip_code: "",
  },
  billing_address: {
    address_1: "",
    address_2: "",
    country_code: "",
    state: "",
    city: "",
    zip_code: "",
  },
  bank_account: {
    account_name: "",
    sort_code: "",
    account_number: "",
    iban: "",
    swift: "",
    bank_name: "",
    address: "",
    address_2: "",
    address_3: "",
  },
  identification: {
    personal_identification_number: "",
    document_type: "",
    place_of_issue: "",
    document_issue_date: "",
    document_expiry_date: ""
  }
}

export const PROFILE_DEFAULT_ERRORS = {
  initials: null,
  first_name: null,
  middle_name: null,
  last_name: null,
  gender: null,
  title: null,
  company_name: null,
  personal_id: null,
  phone: null,
  old_password: null,
  password: null,
  password_confirmation: null,
  billing_address: {
    address_1: null,
    address_2: null,
    country_code: null,
    state: null,
    city: null,
    zip_code: null,
  },
  shipping_address: {
    address_1: null,
    address_2: null,
    country_code: null,
    state: null,
    city: null,
    zip_code: null,
  },
  bank_account: {
    account_name: null,
    sort_code: null,
    account_number: null,
    iban: null,
    swift: null,
    bank_name: null,
    address: null,
    address_2: null,
    address_3: null,
  },
  identification: {
    personal_identification_number: "",
    document_type: "",
    place_of_issue: "",
    document_issue_date: "",
    document_expiry_date: ""
  }
}

export const SELL_AN_ITEM_INITIAL_MODEL = {
  department_uuid: "",
  category_uuid: "",
  reserve: "",
  quantity: "",
  images: [],
  dynamicFields: {}
}

export default {
  // Configuration attributes added to root element of vue project
  mainConfig: CONFIG,
  is_authorized: false,
  signal: 0,
  customer: {},
  titles: [],
  countries: [],
  socketSubscribedLots: {},
  socketLastInteractionTime: null,
  user: {
    token: null,
  },
  modalMsgBox: {
    visible: false,
    title: null,
    content: null,
    headerBgVariant: null,
    headerTextVariant: null,
  },
  settings: null,
  toast: {
    visible: false,
    duration: 0,
    title: null,
    text: null,
    variant: null,
  },
  perPageOptions: CONFIG.perPageOptions,
  auctions: {
    loading: false,
    loaded: false,
    pagination: {
      page: AUCTION_LIST_DEFAULT_PAGE,
      limit: AUCTION_LIST_DEFAULT_LIMIT,
    },
    totalRows: null,
    results: [],
    preserveOldLots: false,
    categories: [],
    departments: [],
    filters: {
      params: null,
      urlParams: null,
    }
  },
  auctionObject: {
    loading: false,
    data: {},
  },
  lots: {
    loading: false,
    loaded: false,
    pagination: {
      page: LOT_LIST_DEFAULT_PAGE,
      limit: LOT_LIST_DEFAULT_LIMIT,
    },
    totalRows: null,
    results: [],
    preserveOldLots: false,
    categories: [],
    departments: [],
    filters: {
      params: null,
      urlParams: null,
    },
    lotInquiryModal: {
      show: false,
      lot: null,
    }
  },
  lotObject: {
    loading: false,
    data: {},
    biddingStarted: false,
    biddingFinished: false,
    maxBidModal: false,
    upcomingBidPopover: {
      show: false,
      low: null,
      high: null,
    },
    upcomingMaxBidPopover: {
      show: false,
      low: null,
      high: null,
    },
  },
  departments: {
    loading: false,
    data: []
  },
  categories: {
    loading: false,
    data: []
  },
  dynamicFields: {
    loading: false,
    data: []
  },
  locationStates: {
    loading: false,
    data: []
  },
  lotQueryParams: JSON.parse(JSON.stringify(LOT_DEFAULT_FILTERS)),
  auctionQueryParams: JSON.parse(JSON.stringify(AUCTION_DEFAULT_FILTERS)),
  favoriteAuctionQueryParams: JSON.parse(JSON.stringify(FAVORITE_AUCTION_DEFAULT_FILTERS)),
  lotListViewType: localStorage.getItem('ARTISIO_LOT_LIST_VIEW_TYPE') || 'grid',
  auctionListViewType: localStorage.getItem('ARTISIO_AUCTION_LIST_VIEW_TYPE') || 'list',
  doNotShowConfirmOnBid: doNotShowConfirmOnBid || {},
  autoReorderMyBidsLots: false,
  socket: {
    isOpened: false,
    loginFinished: false,
    disconnectTime: Date.now()
  },
  invoices: {
    loading: false,
    pagination: {
      page: INVOICE_PAGINATION_PAGE,
      limit: INVOICE_PAGINATION_LIMIT,
    },
    totalRows: 5,
    results: [],
    filters: {
      params: null,
      urlParams: null,
    }
  },
  settlements: {
    loading: false,
    pagination: {
      page: SETTLEMENT_PAGINATION_PAGE,
      limit: SETTLEMENT_PAGINATION_LIMIT,
    },
    totalRows: 5,
    results: [],
    filters: {
      params: null,
      urlParams: null,
    }
  },
  items: {
    loading: false,
    pagination: {
      page: ITEM_PAGINATION_PAGE,
      limit: ITEM_PAGINATION_LIMIT,
    },
    totalRows: 5,
    results: [],
    filters: {
      params: null,
      urlParams: null,
    }
  },
  temporaryItems: {
    loading: false,
    pagination: {
      page: TEMPORARY_ITEM_PAGINATION_PAGE,
      limit: TEMPORARY_ITEM_PAGINATION_LIMIT,
    },
    totalRows: 5,
    results: [],
    filters: {
      params: null,
      urlParams: null,
    }
  },
  sellAnItemModal: {
    show: false,
    flag: null,
    model: {
      ...SELL_AN_ITEM_INITIAL_MODEL,
      images: [...SELL_AN_ITEM_INITIAL_MODEL.images]
      // 'dynamic_fields-[[field_name]]',
    },
    errors: {}
  },
  departmentsForSellAnItem: null,
  categoriesMap: {},
  dynamicFieldsMap: {},
  error: {
    code: null,
    message: null
  },
  filtersMenuOpenMobile: false,
  homePage: {
    departments: {
      loading: false,
      data: []
    }
  },
  isOnline: true,
  lastOnlineTime: null,
  showReloadMessage: false,
  signup: {
    model: {
      initials: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      gender: "",
      title: "",
      preferred_language_code: "",
      email: "",
      company_name: "",
      is_company: false,
      subscribe_to_newsletter: false,
      personal_id: "",
      phone: "",
      password: "",
      password_confirmation: "",
      shipping_address: {
        address_1: "",
        address_2: "",
        country_code: "",
        state: "",
        city: "",
        zip_code: "",
      },
      billing_address: {
        address_1: "",
        address_2: "",
        country_code: "",
        state: "",
        city: "",
        zip_code: "",
      },
      bank_account: {
        account_name: "",
        sort_code: "",
        account_number: "",
        iban: "",
        swift: "",
        bank_name: "",
        address: "",
        address_2: "",
        address_3: "",
      },
      identification: {
        personal_identification_number: "",
        document_type: '',
        place_of_issue: "",
        document_issue_date: "",
        document_expiry_date: ""
      }
    },
    errors: {}
  },
  profile: {
    model: JSON.parse(JSON.stringify(PROFILE_DEFAULT_VALUE)),
    errors: JSON.parse(JSON.stringify(PROFILE_DEFAULT_ERRORS)),
  },
  termsAndConditionsModal: {
    show: false,
    title: null,
    forceToAccept: false,
  },
  clientTimeWhenServerResponded: null,
  serverTime: null
}
